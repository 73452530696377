    /* Start Banner component ----------- */

    .main-banner-bg {
        background-image: url(../../../public/assets/images/home-banner.png);
        /* height: 100%; */
        height: 211px;
        width: 100%;
        padding: 50px 31px 30px 30px;
    }

    .products-banner {
        display: flex;
        /* gap: 20px; */
        list-style-type: none;
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        justify-content: center;
        padding-left: 0rem;
    }

    .products-banner a {
        color: #fff;
    }

    .products-banner>a:not(:last-child) {
        border-right: 2px solid #fff;
        padding: 0px 10px 0px 10px;
    }
    .products-banner a {
        /* border-right: 2px solid #fff; */
        padding: 0px 10px 0px 10px;
    }
    .search-bar-width {
        width: 600px;
        margin: auto;
    }

    .searchheade-block {
        background: #fff;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
    }

    .searchheade-block .input-group {
        border: none;
    }

    .searchheade-block .input-group .input-group-text {
        background: transparent;
        border: none;
        width: 150px;
        font-weight: 600;
    }

    .searchheade-block .form-select {
        border: none;
        font-size: 14px;
        font-weight: 600;
    }

    .searchheade-block .form-control {
        border: none;
        border-left: 1px solid #ddd;
        font-size: 14px;
        min-height: 35px !important;
        font-weight: 600;
    }

    .hide-in-desktop {
        display: none;
    }

    .banner-links input {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }

    /* banner bottom content  */

    .banner-bottom-part {
        overflow: scroll;
        width: 80%;
        margin: auto;
        height: fit-content;
        top: -25px;
        position: relative;
        border-radius: 10px;
        padding: 0 0 0.2rem 0;
        border-radius: 10px;
    }

    .banner-bottom-part::-webkit-scrollbar {
        display: none;
    }

    .banner-bottom-part .banner-bottom-content {
        width: 900px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        margin: auto;
        position: relative;
        /* top: -20px; */
    }

    .banner-bottom-part .banner-bottom-content h6 a {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    .banner-bottom-part .banner-bottom-content p {
        color: #908f8f;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
    }

    .request-quotes {
        display: flex;
        width: 33%;
        gap: 15px;
        align-items: center;
        border-right: 1px solid #c4c4c4;
        justify-content: center;
        padding: 20px 0px;
    }

    .request-quotes:nth-child(3) {
        border: none;
    }

    /* End Banner component ----------- */
    /* Start Products Card component  */

    .apna-pro-bg {
        border-radius: 9px;
        border: 1px solid #d9d9d9;
        background: #fff;
        box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }

    .apna-pro-bg h6 {
        color: #000;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 15px;
    }

    .apna-pro-pera {
        display: none;
    }

    .counter-part1 {
        width: 45%;
    }

    .counter-part1 span {
        color: #1351c1;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
    }

    .counter-part1 p {
        color: #000;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    /* End Products Card component  */
    /* Start Empower Businness component  */

    .empower-bussiness-content {
        /* width: 80%; */
        margin: auto;
        text-align: center;
        margin-top: 30px;
    }

    .empower-bussiness-content h1 {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .empower-bussiness-content p {
        color: #555;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 82%;
        margin: auto;
    }

    /* End Empower Businness component  */
    /* Start Become seller component   */

    .form-section-apna {
        width: 394px;
        border-radius: 9px;
        border: 1px solid #ccc;
        background: #1351c1;
        padding: 25px;
    }

    .form-section-apna h6 {
        color: #fff;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .form-section-apna h6 {
        color: #fff;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .form-section-apna h6 {
        color: #fff;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .form-section-apna p {
        color: #ffffff;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
    }

    .form-section-apna button {
        border-radius: 4px;
        background: #ffb800;
        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        margin-top: 10px;
        padding: 7px;
        height: 45px;
    }

    .form-section-apna input {
        border-radius: 4px !important;
        border: 1px solid #b9b9b9;
        background: #fff;
        color: #908f8f !important;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 15px;
        height: 42px;
    }

    .form-section-apna input[type="radio"] {
        width: 0.9rem !important;
        height: 0.9rem !important;
        border-radius: 50% !important;
        margin-bottom: 0.8rem;
    }

    .form-section-apna input[type="checkbox"] {
        width: 1rem !important;
        height: 1rem !important;
        margin-bottom: 0.7rem;
    }

    .form-section-apna .form-check-input:checked[type=radio],
    .form-section-apna .form-check-input:checked[type=checkbox] {
        background: #ffb800;
        border: 2px solid #ffb800;
    }

    .form-section-apna label.form-check-label {
        font-size: 11px;
        /* margin-left: 10px; */
    }

    .form-section-apna .sel-product-service>div {
        width: 60%;
        text-align: start;
    }

    .form-section-apna .sel-product-service label {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
    }

    .form-section-apna textarea {
        border-radius: 4px !important;
        border: 1px solid #b9b9b9;
    }

    /* .form-section-apna input:last-child { */

    .form-section-apna select {
        border-radius: 4px !important;
        border: 1px solid #b9b9b9;
        background: #fff;
        color: #908f8f;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 15px;
        padding: 10px;
        height: 42px;
    }

    /* .content-section-apna {
  width: 60%;
} */

    .content-section-apna p {
        color: #555;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0.3rem;
    }

    .content-section-apna h3 {
        color: #555;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .content-section-apna h5 {
        color: #555;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0px;
    }

    .help-points {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-top: 15px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.144);
        padding-bottom: 10px;
    }

    .help-points>img {
        width: 75px;
        height: 75px;
        margin-right: 20px;
    }

    /* End Become seller component   */
    /* Start Businss Steps compnent  */
    /* End Businss Steps compnent  */
    /* Start Our comptitors compnent  */

    .our-comp-bg {
        background: rgba(19, 81, 193, 0.07);
        padding: 40px;
        margin-top: 30px;
    }

    .our-comp h1 {
        color: #000;
        font-family: Inter;
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .our-comp p {
        color: #555;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .our-comp button {
        border-radius: 29px;
        background: #1351c1;
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px 25px;
    }

    .our-comp {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .our-comp-1 {
        width: 45%;
    }



    /* End Our comptitors compnent  */
    /* Start Investors component  */

    .apna-investors-section h6 {
        color: #000;
        font-family: Inter;
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        margin-top: 20px;
    }

    .apna-investors-section p {
        color: #555;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: justify;
    }

    .overf-div3 {
        max-width: 100%;
        overflow-x: auto;
    }
    .overf-div3::-webkit-scrollbar {
        display:none;
    }
    .apna-investors {
        min-width: 1080px;
    }

    .apna-investors-section .slick-dots {
        display: none !important;
    }

    .apna-investors-section .slick-slider>button {
        display: none !important;
    }

    .apna-investors-section .investor-details>p {
        font-size: 20px;
        margin: 4.5rem 0;
    }

    .apna-investors-section h2 {
        color: #555;
        font-family: Italiana;
        font-size: 66px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .apna-investors-section ul.slick-dots {
        display: none;
    }

    .apna-investors {
        display: grid;
        grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11%;
        gap: 17px;
    }

    .apna-investors p {
        text-align: center;
    }

    .apna-user {
        border-radius: 9px;
        border: 1px solid #cfcfcf;
        background: #fff;
    }

    .apna-user>img {
        width: 100%;
    }

    .apna-user h3 {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 10px;
    }

    /* End Investors component  */
    /* Start moble footer   */

    .mobile-footer {
        min-width: 320px;
        max-width: 460px;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9994;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
        display: none;
    }

    .mobile-footer .main-content {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
        padding: 0 0.5rem;
    }

    .mobile-footer .main-contnent>div {
        width: fit-content;
        padding: 0.5rem 0.8rem;
        text-align: center;
    }

    .mobile-footer .main-contnent .home>a {
        position: absolute;
        top: -2rem;
        left: 0;
        padding: 0.33rem;
        background: linear-gradient(1deg, #a5a5a5, transparent, transparent);
        border-radius: 50%;
    }

    .mobile-footer .main-contnent .home>a>div {
        width: 51px;
        height: 51px;
        background: #1351c1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 5;
        position: relative;
    }

    /* .mobile-footer .main-contnent.home > a::after  {
    content: "";
    height: 3rem;
    height: 100%;
    background-image: linear-gradient( 135deg, #A0FE65 10%, #FA016D 100%);
    position: absolute;
    left: 0;
    bottom: -1px;
    z-index: 2px

    } */

    .mobile-footer .main-contnent .more>a span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #000;
    }

    .mobile-footer .main-contnent>div P {
        color: #555;
        font-size: 12px;
        font-weight: 600;
    }

    /* End moble footer   */


    /* Start new Mobile Banner css--------------- */
    .show-mobile-banner {
        display: none;
    }
    .show-desktop-banner{
        display: block;
    }

    .mobile-banner {
        padding: 1rem;
        padding-top: 1.5rem;
        max-width: 460px;
    }

    .mobile-banner-bg {
        height: 190px;
        width: 100%;
        background: url("../../../public/assets/images/home-banner.png");
        background-repeat: no-repeat;
        background-position: center;
    }

    .mobile-banner .banner-links ul li>div {
        width: 100%;
        height: 100%;
        gap: 0.6rem !important;
    }

    .mobile-banner .banner-links ul li {
        width: 92px;
        height: 92px;
        text-align: center;
        border-radius: 0.4rem;
        background: linear-gradient(180deg, #FF40E0 0%, #005A80 100%);
        color: #FFF;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1rem;
        padding-right: 0;
        /* 114.286% */

    }

    .mobile-banner .products-banner {
            min-width: 402px;
    }

    .mobile-banner .banner-links ul li.lcard2 {
        background: linear-gradient(180deg, #A41FD2 0%, #8D3232 100%);
    }

    .mobile-banner .banner-links ul li.lcard3 {

        background: linear-gradient(180deg, #631FD2 0%, #93147F 100%);
    }

    .mobile-banner .banner-links ul li.lcard4 {
        background: linear-gradient(180deg, #1FD29C 0%, #4A328D 100%);
    }

    .mobile-banner .banner-links ul li.slcard1 {
        background: linear-gradient(180deg, #113E91 0%, #629AFF 100%);
    }

    .mobile-banner .banner-links ul li.slcard2 {
        background: linear-gradient(180deg, #FFAE00 0%, #E76D06 100%);
    }

    .mobile-banner .banner-links ul li.slcard3 {
        background: linear-gradient(180deg, #C524FE 0%, #DA72FF 100%);

    }

    .mobile-banner .banner-links ul li.slcard4 {
        border-radius: 4px;
        background: linear-gradient(180deg, #539797 0%, #116BBE 100%);
    }

    /* #A41FD2, #8D3232 */

    .mobile-banner .products-banner>a:not(:last-child) {
        border-right: 0px !important;
    }

    .mobile-banner .banner-links .back-btn button {
        color: #E5E5E5;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }

    .mobile-banner .banner-links .overf-div2 {
        max-width: 100%;
        overflow-x: auto;
    }

    .mobile-banner .banner-links .overf-div2::-webkit-scrollbar {
        display: none;
    }


    /* End new Mobile Banner css -----------------*/
    /* -----------------------------------------------------------
| Start Media 
--------------------------------------------------------- */

    @media (max-width: 1199px) {
        .pd-cards .row>div {
            padding: 0 0.2rem;
        }

        .form-section-apna {
            width: 45%;
            gap: 3%;
        }

        .buss-stepper .Mui-active {
            font-size: 37px;
        }

        /* .apna-investors {
            grid-template-columns: 17% 17% 17% 17% 17%;
        } */

        .apna-investors-section .investor-details>p {
            font-size: 19px;
            margin: 1.5rem 0;
        }

        .apna-investors-section .investor-details>p {
            font-size: 19px;
            margin: 1.5rem 0;
        }
    }

    @media (max-width: 1080px) {
        .banner-bottom-part {
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        }
    }

    @media (max-width: 992px) {
        .empower-bussiness-content h1 {
            font-size: 35px;
        }

        .empower-bussiness-content p {
            width: 100%;
        }

        .form-section-apna {
            width: 60%;
            margin: auto;
        }

        .become-seller-p {
            flex-direction: column;
        }

        .become-buyer-p {
            flex-direction: column-reverse;
            gap: 1rem;
        }

        .our-comp h1 {
            font-size: 30px;
        }

        .our-comp-1 img {
            height: 230px !important;
        }

        .buss-stepper .Mui-active {
            font-size: 28px;
        }

        /* .form-section-apna {
    width: 40%;
  } */
        .third-section h2 {
            font-size: 40px;
            line-height: 49px;
        }

        .counter-part span {
            font-size: 37px;
            line-height: 57px;
        }

        .content-section-apna h3 {
            font-size: 28px;
        }

        .help-points {
            gap: 13px;
        }

        .help-points img {
            width: 55px;
            height: 55px;
        }

        .apna-investors-section .investor-details>p {
            margin: 3rem 0;
        }

        .investory-slider,
        .apna-pro-bg>img {
            width: 100% !important;
        }
    }

    @media (max-width: 767px) {

        /* .request-quotes {
  flex-direction: column;
  padding: 1.4rem 1rem ;
} */
        .main-banner-bg {
            height: 236px;
        }

        .third-section h2 {
            font-size: 37px;
        }

        /* .apna-pro-bg {
    text-align: center !important;
  } */
        .empower-bussiness-content {
            width: 100%;
        }

        .empower-bussiness-content>.row {
            gap: 10px;
        }

        .empower-bussiness>.row>div:first-child {
            margin-bottom: 1rem;
        }

        .form-section-apna,
        .content-section-apna {
            width: 100%;
        }

        /* .apna-investors {
            grid-template-columns: 23% 23% 23% 23%;
        } */

        .investory-slider>div:last-child {
            display: none;
        }

        .apna-investors-section h6 {
            font-size: 40px;
        }

        .apna-investors-section h2 {
            font-size: 55px;
        }
    }

    @media (max-width: 600px) {
        /* .apna-investors {
            grid-template-columns: 30% 30% 30%;
        } */

        .search-bar-width {
            width: 100%;
        }
    }

    @media (max-width: 575px) {
        .our-comp-1>.row {
            gap: 10px;
        }

        .our-comp>.our-comp-1:last-child {
            text-align: center !important;
        }

        .our-comp-1 h1 {
            font-size: 35px;
        }
    }

    @media (max-width: 530px) {
        .products-banner {
            font-size: 18px;
        }

        .products-banner>a:not(:last-child) {
            padding-right: 8px;
        }
    }

    @media (max-width: 460px) {
        .show-desktop-banner{
            display: none;
        }
    .show-mobile-banner{
        display: block;
    }
        /* .main-banner-bg {
            padding-top: 35px;
        } */

        .hide-in-desktop {
            width: 90%;
        }

        .search-bar-width .hide-in-desktop img {
            width: 15px;
            height: 15px;
        }

        .form-control {
            font-size: 0.8rem;
            height: 34px;
        }

        .products-banner {
            font-size: 14px;
            line-height: 15px;
            gap: 6px;
        }

        .products-banner>a:not(:last-child) {
            padding-right: 5px;
        }

        .products-banner li {
            padding-right: 3px;
        }

        .banner-services {
            font-size: 12px;
        }

        .request-quotes {
            gap: 10px;
            width: 27%;
            padding: 0px 9px;
        }

        .request-quotes img {
            width: 2rem;
        }

        .banner-bottom-part {
            width: 90%;
        }

        .banner-bottom-part .banner-bottom-content h6,
        .banner-bottom-part .banner-bottom-content h6 a {
            font-size: 14px;
            line-height: 14px;
        }

        .apna-investors-section h6 {
            font-size: 28px;
        }

        .apna-investors-section h2 {
            font-size: 44px;
        }

        .empower-bussiness-content p {
            width: 100%;
        }

        .apna-investors-section .investor-details>p {
            margin: 1.5rem 0;
            font-size: 17px;
        }

        .mobile-footer {
            display: block;
        }

        .empower-bussiness-content h1 {
            font-size: 29px;
        }

        .third-section h2 {
            font-size: 31px;
            line-height: 39px;
        }

        .counter-part span {
            font-size: 36px;
            line-height: 43px;
        }

        .our-comp-bg {
            /* padding: 25px; */
            padding: 14px;
        }
        .our-comp-1  img {
            width: 100%;
        }

        .our-comp h1 {
            font-size: 31px;
        }

        .our-comp .our-comp-1 .row>div {
            margin-top: 0.6rem;
        }

        .apna-investors {
            gap: 10px;
        }
    }

    .breadcrumb-sction {
        display: none;
    }

/* -----------------------------------------------------------
| End Media 
--------------------------------------------------------- */