ul li {
  list-style: none;
}

/* Start Banner  */
.faq-sb-banner h5 {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  line-height: 16px;
  margin: 1.3rem 0;
  text-align: center;
  line-height: normal;
}

.faq-sb-banner .banner-bg-img {
  background: url("../../public/assets/images/seller-buyer.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 290px;
}
/* End Banner  */

/*Start tart Faq Content */
.faq-sb-content .left-headings {
  width: 345px;
  height: 537px;
  flex-shrink: 0;
  overflow-y: auto;
}

.faq-sb-content .left-headings a {
  color: #555;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  padding: 0.7rem 1rem;
  /* height: 48px; */
  border-bottom: 1px solid DCDCDC;
}

.active-heaidng {
  background-color: #edf4ff;
}

/* right  */
.right-answers .breadcrum p{
  color: #908f8f;
  font-size: 14px;
  font-weight: 500;
}
.right-answers .breadcrum h4 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.right-answers .accordion-item  button{
  color: #555;
  font-size: 16px;
  font-weight: 400;
}

.active-answer {
  color: #1351C1 !important; 
  background-color: #edf4ff;

}

/* End Faq Content */



/*Start Media Query */



/* Start Media Query */
