/* Start Second Common  sec */
.second-common h2 {
  color: #000;
  font-size: 22px;
  font-weight: 600;
}
.second-common h4 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
.second-common .categories .right .card {
  width: 291px;
  /* height: 165px; */
}

.second-common .categories .left > img{
  width: 320px;
  height: 346px;
}

.second-common .categories .right .card a {
  color: #1351c1;
  font-size: 12px;
  text-decoration-line: underline !important;
  font-weight: 600;
  text-wrap:  nowrap;
}

.second-common .categories .content-img .content p {
  color: #000;
  font-size: 14px;
  margin-bottom: 0.3rem;
  text-wrap: wrap;
}

/* right */

/* End Second Common  sec */






/*-------------------------------
|  Media Query 
---------------------------------------------------*/
@media(max-width:1399px) {
    .second-common .categories .right .card {
        width: 264px;
        height: auto;
        padding: 0.7rem !important;
    }
}

@media(max-width:1199px) {
    .second-common .categories .right .card {
        width: 220px;

    }
}


@media(max-width:992px) {
    .second-common .categories .left > img {
        width: 100%;
        height: 250px;
        margin-bottom: 1.3rem;
    }

    .second-common .categories .right .card {
        width: 217px;

    }

    .categories .row {
      gap: 1rem;
    }
    .categories .row .left{
      text-align: center;
    }
    .categories .row .right .content-img .image{
      margin: auto;
    }
   
}

@media(max-width: 490px) {
    .second-common .categories .right .card {
        width: 320px;
    }
}
