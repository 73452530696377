/* Success Component1 */
.success-comp1 {
    font-family: Inter;
}

.success-comp1 h1 {
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
}

.success-comp1 p.summary {
    color: #555;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    line-height: 23px;
    width: 50%;

}

/* Success Comonent2 */

.success-comp2 .left-content {
    width: 65%;
}

.success-comp2 .right-content {
    width: 30%;
}

.success-comp2 .story-details p.story-desc {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 2rem auto 2rem 0;
    /* width: 80%; */
}

.success-comp2 .right-content p.story-desc {
    margin: 1.3rem auto 1.3rem 0;
    font-size: 19px;

}

.success-comp2 .story-details .like-dislike span {
    font-size: 18px;
    color: #555;
}

.success-comp2 .story-details .like-dislike p {
    color: #555;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.success-comp2 .right-content .heading h4 {
    color: #FFF;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.success-comp2 .right-content .heading {
    border-radius: 4px 4px 0 0;
    background: #1351C1;
}





/* Start Media  */

@media(max-width:1199px) {
    .success-comp2 .story-details .comma img {
        width: 20px !important;
    }
    .success-comp2 .story-details p.story-desc {
        font-size: 17px;
        margin: 1.5rem auto 1.5rem auto;
        width: 90%;
    }

    .success-comp2 .story-details .user-img-name img {
        width: 40px !important;
    }
    
    .success-comp2 .story-details .like-dislike span{ 
        font-size: 16px;
    }
    .success-comp2 .story-details .user-location  {
        margin-top: 0.3rem !important;
        margin-bottom: 0.1rem !important;
    }
    .success-comp2 .story-details .user-location img {
            width: 20px !important;
    }
    .success-comp2 .story-details .user-location h5,
    .success-comp2 .story-details .like-dislike p {
        font-size: 14px;
    }

    .success-comp2 .right-content p.story-desc {
        font-size: 16px;
        text-align: justify;
        margin: 0.8rem auto 0.9rem 0;
    }

    .user-details-1 h4 {
        font-size: 18px;
    }

    .user-details-1 h3 {
        font-size: 14px;
    }
}

@media(max-width: 992px) {
   
    .success-comp1 p.summary {
        width: 65%;
    }
    .success-comp2  {
        flex-direction: column;
        justify-content: center !important;
        width: 100%;
    }

    .success-comp2 .left-content,.success-comp2 .right-content {
        width: 75%;
        margin: auto;
    }
    .success-comp2 .right-content .tStory {
        display: flex;
        flex-direction: row-reverse;
        padding-top: 1rem;
    }
    .success-comp2 .right-content .tStory > div {
        width: 49%;
        
    }




}

@media(max-width: 890px) {
    .success-comp2 .left-content,.success-comp2 .right-content {
        width: 85%;
        margin: auto;
    }
}

@media(max-width: 767px) {

    .success-comp1 h1 {
        font-size: 33px;
    }
    .success-comp1 p.summary {
        width: 85%;
    }

    .success-comp2 .left-content,
    .success-comp2 .right-content {
            width: 90%;
    }

    .success-comp2 .left-content .col-md-6 {
        width: 50%;
    }

    .success-comp2 .story-details p.story-desc {
        width: 100%;
        font-size: 15px;
        margin: 1rem auto 1rem auto;
    }


}

@media(max-width: 660px) {
    .success-comp2 .left-content,
    .success-comp2 .right-content {
            width: 95%;
    }
}

@media(max-width: 600px) {
    .success-comp1 p.summary {
        width: 95%;
        line-height: 20px;
        font-size: 14px;
    }
    .success-comp1 h1 {
        font-size: 30px;
        line-height: 36px;
    }
    .success-comp2 .left-content > div {
        flex-direction: column-reverse;
        gap: 0.6rem;
    }

    .success-comp2 .left-content .col-md-6,
    .success-comp2 .right-content .tStory > div {

        width: 100%;
    }

    .success-comp2 .right-content .large-img  {
        display: none;
    }
}