
.directory-banner h3 {
  font-size: 35px;
}

/*Second Section  */
.directory-comp-2nd p {
  font-size: 15px;
  color: #555;
}

.directory-comp-2nd .cards-container .card .card-img-top {
  padding: 16px;
}

.directory-comp-2nd .cards-container {
  overflow-x: auto;
}
.directory-comp-2nd .cards-container::-webkit-scrollbar {
  display: none;
}

.directory-comp-2nd .cards-container .cards {
  min-width: 1300px;
}

.directory-comp-2nd .cards-container .card .card-title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.directory-comp-2nd .cards-container .card .card-links a,
.directory-comp-3rd .left .btn-links a {
  /* color: #FFF; */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  background-color: #1351c1;
  color: #fff;
  border-radius: 0.3rem;
}



.directory-comp-2nd .cards-container .card .card-links a:last-child,
.directory-comp-3rd .left .btn-links a:last-child {
  background-color: #ffb800;
  color: #000;
}

.directory-banner {
  background-color: #1351c1;
}

.directory-banner .highlited-points p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.directory-banner .highlited-points span {
  color: #ffc8c9;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  display: inline-block;
  margin-bottom: 0.8rem;
}

.directory-banner .highlited-points span.lightgreen {
  color: #00DC23;
}

.directory-banner .highlited-points span.golden {
  color: #F1B31C;

}

.directory-banner .container {
  width: 85% !important;
}

.directory-banner .container .highlited-points {
  flex-wrap: nowrap !important;
}

/* Medai Query  */
@media(max-width: 1199px) {
  .directory-banner .highlited-points p {
    font-size: 16px;
  }

  .directory-banner .highlited-points span {
    font-size: 32px;
    margin-bottom: 0.4rem;
  }
}


@media(max-width: 992px) {
  .directory-comp-2nd .top-content p {
    width: 100% !important;
  }
}


@media(max-width: 767px) {
  .directory-banner h3 {
    font-size: 26px;
  }

  .directory-banner .highlited-points p {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 0.5rem;
  }

  .directory-banner .highlited-points span {
    font-size: 27px;
    margin-bottom: 0;
  }

}


@media(max-width: 575px) {  
  .directory-banner .container {
    width: 95% !important;
  }
  .directory-banner{
  padding: 1.3rem 0 !important;
}
}
@media(max-width: 490px) {

  .directory-comp-3rd .right .cards>div {
    flex-direction: column;
  }

  .directory-comp-2nd h3 {
    font-size: 26px;
  }

  .directory-comp-3rd .right .cards > div div {
    padding-bottom: 0.2rem !important;
  }
    
}

@media(max-width: 416px) {
  .directory-banner h3 {
    font-size: 22px;
  }
  .directory-banner .container .highlited-points{
    flex-wrap: wrap !important;
  }
  .directory-banner .container .highlited-points >div{
    width: 48%;
  }
    
}