/* ED  Component 2------- */

* {
    font-family: Inter;
}

.event-detail-comp2 h3 {
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.event-detail-comp2 p {

    color: #555;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.48px;
}

.event-detail-comp2 .tab-btns button {
    color: #555;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1rem;
}

.event-detail-comp2 .tab-btns {
    border-bottom: 1px solid #BFBFBF;
    margin-bottom: 1.5rem;
}

.event-detail-comp2 .intro-cards .com-card {
    border-radius: 9px;
    background: linear-gradient(180deg, #1351C1 0%, #284E92 100%);
    padding: 1.5rem;
    width: 31.5%;
}

.event-detail-comp2 .intro-cards .com-card>img {
    width: 85px;
    height: 85px;
}

.event-detail-comp2 .intro-cards .com-card h5 {

    color: #FFF;
    font-size: 28px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.84px;
}

.event-detail-comp2 .intro-cards .com-card p {

    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.event-detail-comp2 .overf-div {
    max-width: 100%;
    overflow-x: auto;
}

.event-detail-comp2 .overf-div::-webkit-scrollbar {
    display: none;
}

.event-detail-comp2 .tab-btns {
    border-bottom: 1px solid #BFBFBF;
    min-width: 800px;
}

.event-detail-comp2 .tab-content .gallery .img-card {
    max-width: 244px;
    max-height: 295px;
    border-radius: 4px;
}

.event-detail-comp2 .tab-content .gallery .img-card>img {
    border: 4px;
    width: 100%;
}

.event-detail-comp2 .videos video {
    width: 418px;
    height: 295px;
}

.event-detail-comp2 .join-now p.bold-para {
    color: #555;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.event-detail-comp2 .join-now form select,
.event-detail-comp2 .join-now form input {
    padding: 0.7rem;
    width: 100%;
    border-radius: 0.3rem;
    border: 1px solid #E9ECEF;
}

.event-detail-comp2 .join-now form .dd-input>div,
.event-detail-comp2 .join-now form .dd-input>div > div,
.event-detail-comp2 .join-now form > div > div
 {
    width: 49%;
}
.event-detail-comp2 .join-now form >  div p {
    font-size: 14px;
    color: red;
}

.event-detail-comp2 .join-now form textarea {
    width: 100%;
    border: 1px solid #E9ECEF;
    border-radius: 0.4rem;
    padding: 0.7rem;
    font-size: 16px;

}

.event-detail-comp2 .join-now form input[type = "submit"] {
    width: 25%;
    color: #EEE;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    background-color: #1351C1;
    border: none;
}

.event-detail-comp2 .join-now form input:focus-visible,
.event-detail-comp2 .join-now form textarea:focus-visible {
    outline: none;
}

.event-detail-comp2 .join-now form input::placeholder {
    color: #555;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
}


.active-btn{
    color: #1351C1 !important;
    border-bottom: 2px solid #1351C1;
}


/* ED Component3  */
.event-detail-comp3 h4 {
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    margin-bottom: 2rem;
}

.event-detail-comp3 h4 span {
    color: #1351C1;
}

.event-detail-comp3 .cards .card {
    border: none;
    border-radius: 4px;
    background: #FFF;
    max-width: 323px;
    padding: 1rem;
    width: fit-content !important;
    align-items: center;

    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.02);
}

.event-detail-comp3 .cards .card .card-body {
    padding-left: 0;
    padding-right: 0;
}

.event-detail-comp3 .cards .card .date-loc p {
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.event-detail-comp3 .cards .card .date-loc svg {
    fill: #555;
}

.event-detail-comp3 .cards .card p.bold-para {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.event-detail-comp3 .cards .card .img-div span {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: #FFAE00B8;
    padding: 0.6rem 1rem;
    border-radius: 0.2rem;
    position: absolute;
    top: 0.7rem;
    left: 0.7rem;

}