/* -------------------------------------
 | Seller & User dashboard  Css written here 
---------------------------------------*/


/* header ^^^^^^^^^^^^^^^^ */
.seller-dashb-header .navbar .navbar-nav .seller-d-link a {
  color: #1351C1;
  font-size: 16px;
  font-weight: 600;
}

.seller-dashb-header .header-mid-cont .select-cont,
.seller-dashb-header .header-mid-cont h6 {
  color: #555;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.seller-dashb-header .header-mid-cont p{
color: #555;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

/* .seller-dashb-header .navbar .navbar-nav .more-icons .micon-parent .micon{
  display:table-column;
} */

.seller-dashb-header .navbar .navbar-nav .more-icons .micon a {
  /* border: 1px solid grey; */
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  background-color: #fff;

}

.seller-dashb-header .navbar .navbar-nav .more-icons .micon a:hover {
  background-color: #95acf55e;
  border-radius: 0.4rem;

}

.seller-dashb-header .navbar .navbar-nav .more-icons .micon span {
  padding: 0.8rem 0.6rem;
  background: #e5e5e5;
  border-radius: 50%;
  margin: 0.5rem auto;
}

.seller-dashb-header .navbar .navbar-nav button::after {
  display: none;
}

/* Notificatoin */

.notification {
  max-width: 450px;
  position: fixed;
  width: 320px;
  background: #d8d8d8a6;
  height: 100vh;
  top: 10%;
  right: -100%;
  z-index: 999;
  background: #fff;
  transition: 0.5s ease;
}

.notif-content .notif-body {
  max-height: 500px;
  overflow-y: auto;
}

.notif-content .notif-body::-webkit-scrollbar {
  display: none;
}

.notif-content .top-cont {
  margin-bottom: 1rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #d8d8d8;
}

.notif-content .top-cont h6 {
  color: #222529;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  /* 160% */
}

.notif-content .top-cont button {
  font-size: 1rem;
  font-weight: 600;
  flex-shrink: 0;
}

.notif-content .notif-body .notif1 img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.notif-content .notif-body .notif1 h5 {
  color: #222529;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 153.846% */
}

.notif-content .notif-body .notif1 p {
  color: #5A6474;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.notif-content .notif-body .notif1 p.time-ago {
  font-weight: 400;
}

.notif-content .notif-body .notif1 .notif-dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: red;
  display: inline-block;

}

.notif-content .notif-body .notif1 span:not(.notif-dot) {
  color: #5A6474;
}

/* Sidebar ^^^^^^^^^^^^^^^^^^ */
.no-arrow:after {
  display: none;
}

.seller-dashboard-sidebar .seller-tab-links .accordion-item button,
.seller-dashboard-sidebar .seller-tab-links .accordion-item .accordion-body ul a {
  border: none;
  border-bottom: 1px solid #f8f8f8;
  color: #000;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  font-weight: 500;
  transition: 0.4s ease;
}

.seller-dashboard-sidebar .seller-tab-links .accordion-item .accordion-body ul {
  padding-left: 0.5rem;
}

.seller-dashboard-sidebar .seller-tab-links .accordion-item .accordion-body ul li {
  padding: 0.8rem 0;
  transition: 0.4s ease;
}

.seller-dashboard-sidebar .seller-tab-links .accordion-item .accordion-body ul li:hover {
  padding-left: 0.8rem;
}

.seller-dashboard-sidebar .seller-tab-links .accordion-item button:hover {
  cursor: pointer;
  padding-left: 1.5rem;

}

.active-accordion {
  background-color: #FFB800;
  border-bottom: 0;
}

/* responsive/toggle sidebar-----------------------  */
/* .mobile-sd-sidebar .seller-name-title {
  border: 2px solid;
  z-index: 4;
  background: #212529;
  left: 5rem;
  top: 2rem;
  border-radius: 0.4rem;
  opacity: 0;
  transition: 0.4s ease;
}
.mobile-sd-sidebar .seller-img-div:hover .seller-name-title {
  opacity: 1;
}

.show-on-hover{
  position: absolute;
  left: 3rem;
  top: 1rem;
  opacity: 0;
  transition: 0.4 ease;
  padding: 0.4rem 1rem;
  background-color: #fff;
}

.mobile-sd-sidebar  .seller-dashboard-sidebar .seller-tab-links .accordion-item button:hover  .show-on-hover
 {
  opacity: 1 !important;
} */






/* End Side bar  */



/* Right main contnet ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
.sellerdashboard-content .right .card-block {
  border-radius: 0.4rem;
}
.sellerdashboard-content .right .card-block P {
 margin-bottom: 0;
 text-align: center;
}

.sellerdashboard-content .hpoints-graph .card h5 {
  color: #414D55;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01px;
  margin-top: 0.5rem;
}

.sellerdashboard-content .hpoints-graph .card img {
  /* margin: auto; */
}

.sellerdashboard-content .hpoints-graph .card p {
  color: #908F8F;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0.2rem;
}

.sellerdashboard-content .hpoints-graph .card span.digit {

  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0.3rem;
}

.sellerdashboard-content .hpoints-graph .card span.percent {
  color: #555;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.417px;
}

/*  Graph ^^^^^^^^^*/

.sellerdashboard-content .hpoints-graph .graph .bar-chart canvas {
  max-width: 290px;
}

.sellerdashboard-content .hpoints-graph .graph .graph-content h4 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.417px;
  text-transform: capitalize;
}

.sellerdashboard-content .hpoints-graph .graph .graph-content span.view {
  color: #000;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.417px;
}

.sellerdashboard-content .hpoints-graph .graph .graph-content span.bg-lgreen {
  color: #068431;
  font-size: 12px;
  font-weight: 700;
  padding: 0.2rem 1rem;
  background-color: #A1F8BF;
}


/* main Right secetion2 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^*/

.sellerdashboard-content .right h3 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.sellerdashboard-content .right button {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(255, 184, 0, 0.40);
  border: none;
  border-radius: 0.3rem;
  padding: 0.5rem 1.4em;
}

.active-db-btn {
  background-color: #FFB800 !important;
}

.sellerdashboard-content .right .right-sec2 table {
  min-width: 1005px;
}

.sellerdashboard-content .right .right-sec2 table th {
  color: #000;
  font-size: 14px;
  font-weight: 500;

}

.sellerdashboard-content .right .right-sec2 table td {
  color: #555;
  font-size: 13px;
  font-weight: 400;
}

.sellerdashboard-content .right .right-sec2 .chat-box {
  width: 28%;
}

.sellerdashboard-content .right .right-sec2 .chat-box .searchbar input {

  width: 100%;
  padding: 0.7rem;
  border-radius: 0.4rem;
  border: 1px solid #bcbcbc;
}

.sellerdashboard-content .right .right-sec2 .chat-box .searchbar span {

  position: absolute;
  right: 1rem;
  top: 0.9rem;
  color: lightgrey;
  font-size: 1.2rem;
}

.sellerdashboard-content .right .right-sec2 .chat-box a {
  color: #908F8F;
  font-size: 15px;
  font-weight: 400;
  text-decoration-line: underline !important;
}


/* main Right secetion3 ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^*/

.sellerdashboard-content .right .right-sec3 .active-products img {
  max-width: 60px;
  max-height: 60px;

}

.sellerdashboard-content .right .right-sec3 .active-products .product-content .pd-type {
  color: #1351C1;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.417px;
  text-transform: uppercase;
}

.sellerdashboard-content .right .right-sec3 .active-products .product-content h5,
.sellerdashboard-content .right .right-sec3 .active-products .product-content .price {
  color: #555;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.sellerdashboard-content .right .right-sec3 h5 {
  color: #555;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.sellerdashboard-content .right .right-sec3 .recp-search >  div:not(:last-child),
.sellerdashboard-content .right .right-sec3 .recs-search >  div:not(:last-child){
  border-bottom: 1px solid #E7E7E7;
}

.sellerdashboard-content .right .right-sec3 .active-products .product-content .price {
  font-size: 14px;
}

/* Analysistics-------- */
.sellerdashboard-content .right .right-sec3 .analystics .global-img span {
  color: #414D55;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
}

.sellerdashboard-content .right .right-sec3 .analystics .global-img p {
  color: #727677;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.sellerdashboard-content .right .right-sec3 .analystics .recent-search ul li {
  list-style: disc;
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 0.4rem;
}

.sellerdashboard-content .right .right-sec3 .analystics .recent-search ul li::marker {
  color: #1351C1;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
}


/* Company Review */
.sellerdashboard-content .right .right-sec3 .company-reivew .reviews-box .progress {
  height: 6px !important;
}

.sellerdashboard-content .right .right-sec3 .company-reivew .reviews-box .progress .progress-bar {
  background: goldenrod !important;
}

.sellerdashboard-content .right .right-sec3 .company-reivew .user-reviews .card h6.user-name {
  color: #555;
  font-size: 16px;
  font-weight: 700;
}

.sellerdashboard-content .right .right-sec3 .company-reivew .user-reviews p {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.sellerdashboard-content .right .right-sec3 .alert-box button {
  color: #555;
  font-size: 12px;
  font-weight: 500;
  width: 49%;
  background: #C5FFBC75;
  padding: 0.8rem;
}


/*----------------------------------------------
 |  Start User Dashbaord Css 
 ---------------------------------------------------------*/

.user-das-content .right .top-alert button {
  font-size: 14px !important;
}

.user-das-content .hpoints-graph .card {
  width: 23%;
}

.user-das-content .hpoints-graph .card h5 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0.1rem;
}

.user-das-content .hpoints-graph .card p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.user-das-content .hpoints-graph .card span.percent {

  width: fit-content;
  /* margin: auto; */
  background: #fff;
  padding: 0.2rem 0.9rem;

}

.user-das-content .hpoints-graph .graph-content .user-photo span {
  bottom: 0.5rem;
  right: 2.4rem;
}

.user-das-content .hpoints-graph .graph-content h5,
.user-das-content .hpoints-graph .pie-chart p {
  color: #414D55;
  font-size: 16px;
  font-weight: 500;
}

.user-das-content .hpoints-graph .graph-content .pie-chart canvas {
  max-width: 215px !important;
}

.user-das-content .right .right-sec3 .active-products img {
  max-width: 111px;
  max-height: 111px;
  border-radius: 0.6rem;
}

.user-das-content .right .right-sec3 .active-products .product-content .price span {
  border: 1px solid #A2C4D4;
  font-size: 1.2rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
}

.user-das-content .right .right-sec3 .wishlist .product-content a {
  color: #555;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.user-das-content .right .right-sec3 .wishlist .product-content {
  width: 100%;
}

.sellerdashboard-content .top-btns {
  min-width: 460px;
}

.overfx-div {
  max-width: 100%;
  overflow-x: auto;
}


/*-----------------------------------------------------
| End User Dashbaord Css 
-------------------------------------------------------------------*/


/*-----------------------------------------------------
|Start Caht Css 
-------------------------------------------------------------------*/

.chat .chat-sidebar {
  background-color: #F6FAFF;
}



.chat .chat-sidebar form {
  border: 1px solid #908F8F;
  width: 90%;
  margin: auto;
  border-radius: 0.4rem;
}

.chat .chat-sidebar form span {
  font-size: 19px;
  color: #908F8F;
  top: 0.6rem;
  right: 1rem;
}

.chat .chat-sidebar form input {
  padding: 0.75rem;
}

.chat .chat-sidebar form input::placeholder {
  color: #908F8F;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.chat .chat-sidebar form input:focus-visible {
  outline: none;
}

.chat .chat-sidebar .users .user h4 {
  color: #555;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
}

.active-symbol {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50px;
  background-color: #1ED330;
  display: inline-block;
}

.chat .chat-sidebar .users .user .user-img span {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50px;
  background-color: #1ED330;
  display: inline-block;
  margin-bottom: -0.6rem;
  margin-left: -0.6rem;
}

.chat .chat-sidebar .users .user p.time {

  color: #555;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

/* user */
.chat .chat-sidebar .users .user:not(:last-child) {
  border-bottom: 1px solid #B9B9B9;
}

.chat .chat-sidebar .users .user p.disc {

  color: #555;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
}

.active-user {
  color: #fff !important;
  /* background: linear-gradient(90deg, #1351C1 2.1%, #1351C1 20.5%, rgba(19, 81, 193, 0.81) 99.88%); */
  background: linear-gradient(90deg, #1351c169 2.1%, #1351c138 20.5%, rgb(230 239 255 / 81%) 99.88%);

}


.chat .chat-body .call-div {
  border-bottom: 1px solid #B9B9B9;
}

.chat .chat-body .call-div .togglebar {
  display: none;
}

.chat .chat-body .call-icons span {
  font-size: 20px;
  padding: 0.5rem 0.7rem;
  border-radius: 50%;
  color: #545454;
  background-color: #E9ECEF;
  line-height: normal;
}

.chat .chat-body .chat-content p {
  color: #555;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #EBF4FB;
  float: left;
}

.chat .chat-body .chat-content .my-chat {
  margin-bottom: 0.6rem;
}

.chat .chat-body .chat-content .my-chat p {
  float: right;
}

.chat .chat-body .add-type-text {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #B9B9B9;
}

.chat .chat-body .add-type-text textarea {
  border: none;
  outline: none;

}

.chat .chat-body .add-type-text textarea:focus-visible {
  outline: none;
}

.chat .chat-body .add-type-text .dd1 button {
  width: fit-content;
  padding: 0.2rem;
  border-radius: 50%;
  font-size: 17px;
  background-color: #EBF4FB;
}

.chat .chat-body .add-type-text .add-file input,
.chat .chat-body .add-type-text input[type="submit"] {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  z-index: 5;
  position: absolute;
  opacity: 0;
  cursor: pointer;

}

.chat .chat-body .add-type-text .dd1 button:after {
  display: none;
}


/*-----------------------------------------------------
|End Caht Css 
-------------------------------------------------------------------*/



/* Start Media Query ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

@media(max-width:1399px) {
  .sellerdashboard-content .hpoints-graph {
    flex-direction: column;
    gap: 1.5rem;
  }

  .sellerdashboard-content .hpoints-graph>div:first-child {
    width: 100%;
    gap: 0.6rem;
    /* margin: auto; */
  }

  .sellerdashboard-content .hpoints-graph>div:first-child .card {
    width: 25% !important;
  }

}

@media(max-width:1199px) {
  .user-das-content>div {
    padding: 1.5rem 2rem !important;
  }

  .user-das-content .hpoints-graph {
    flex-wrap: wrap;
    gap: 1.3rem;
  }

  .user-das-content .hpoints-graph .col-lg-8,
  .user-das-content .hpoints-graph>div:last-child {
    width: 100%;
  }

  .user-das-content .hpoints-graph>div:last-child {
    justify-content: space-between;
    margin: auto 0 !important;
  }


  /* .user-das-content .right .right-sec2, */
  .sellerdashboard-content .right .right-sec2,
  .user-das-content .right .right-sec3>div:not(:last-child) {
    flex-direction: column;
    gap: 1.3rem;
  }

  .sellerdashboard-content .right .right-sec2>div,
  .sellerdashboard-content .right .right-sec2 .chat-box,
  .sellerdashboard-content .right-sec2 .chat-box,
  .user-das-content .right .right-sec3>div:not(:last-child)>div {
    width: 100%;
  }

  .sellerdashboard-content .right .right-sec3>div {
    flex-wrap: wrap;
  }

  .sellerdashboard-content .right .right-sec3>div>div {
    width: 65%;
  }

  .sellerdashboard-content .right .right-sec3 .analystics {
    width: 30%;
  }


  .user-das-content .right .top-alert button {
    font-size: 12px !important;
  }

  .user-das-content .right .top-alert button a {
    margin: 0.2rem auto !important;
  }

}


@media(max-width: 992px) {

  .seller-dashb-header .navbar-nav,
  .sellerdashboard-content>div.row {
    flex-direction: row;
  }

  .sellerdashboard-content>div.row .right {
    width: 75%;
  }


  .user-das-content .right .right-sec2 .table-div {
    max-width: 660px !important;
  }
}

@media(max-width: 900px) {
  .sellerdashboard-content>div {
    padding: 1.5rem 1rem !important;
    padding-left: 1rem !important;
  }

  .sellerdashboard-content>div .col-md-3 {
    width: 17%;
  }

  .sellerdashboard-content .sidebar-dashboard ul li a {
    padding: 9px 0px;
  }

  .sellerdashboard-content>div.row .right {
    width: 82%;
  }

  .sidebar-dashboard .user-info {
    display: none;
  }

  .user-das-content>div .col-md-3 {
    width: fit-content;
    max-width: 13%;
  }

  .dashboard-dropdown-toggle {
    display: block !important;
  }
}

@media(max-width: 860px) {

  .user-das-content .right .right-sec2 .table-div {
    max-width: 615px !important;
  }
}

@media(max-width: 767px) {

  /* .sellerdashboard-content .hpoints-graph .col-lg-8 {
    flex-wrap: wrap;
    gap: 0.6rem !important;
}
.sellerdashboard-content .hpoints-graph .card {
  width: 31%;
} */

.seller-dashb-header .navbar{
  justify-content: center !important;
}

  .sellerdashboard-content>div {
    flex-direction: column !important;
  }

  .sellerdashboard-content>div .col-md-3,
  .sellerdashboard-content>div.row .right {
    width: 100%;
    max-width: 100%;
  }

  .user-das-content>div>.col-lg-3 {
    display: none;
  }

  .user-das-content .right .right-sec3 .alert-box {
    flex-direction: column;
    gap: 0.5rem !important;
  }

  .sellerdashboard-content .right .right-sec3 .alert-box button {
    width: 100% !important;
  }

  .chat .chat-body .call-div .togglebar {
    display: block;
  }

  .chat .chat-sidebar {
    /* display: none; */
    background-color: #F6FAFF;
    position: absolute;
    left: 1rem;
    z-index: 99;
    overflow-y: scroll;
    max-height: 100%;
    transition: 0.7s ease;

  }

  .chat .chat-sidebar::-webkit-scrollbar {
    display: none;
  }

}

@media(max-width: 640px) {
  .user-das-content .hpoints-graph .col-lg-8 {
    flex-wrap: wrap;
    gap: 1rem !important;
  }

  .user-das-content .hpoints-graph .card {
    width: 48%;
  }

  .user-das-content .right .right-sec2 .table-div {
    max-width: 450px !important;
  }

  .sellerdashboard-content .right button {
    font-size: 12px;
  }

  .user-das-content>div {
    padding: 1rem 0.5rem !important;
  }

  .right .right-sec3 .active-products .product-content h5 {
    font-size: 14px;
  }


  .user-das-content .right .right-sec3 .wishlist .product-content a {
    font-size: 17px !important;
  }

  .user-das-content .right .right-sec3 .active-products .product-content .price span {
    font-size: 1rem;
  }

}


@media(max-width: 575px) {
  .sellerdashboard-content .hpoints-graph>div:first-child {
    flex-wrap: wrap;
  }

  .sellerdashboard-content .hpoints-graph>div:first-child .card {
    width: 31% !important;
  }

  .sellerdashboard-content .right .right-sec3>div>div,
  .sellerdashboard-content .right .right-sec3 .analystics {
    width: 100%;
  }


}

@media(max-width: 490px) {
  .user-das-content .right .right-sec2 .table-div {
    max-width: 380px !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 14px !important;
  }

  .react-calendar__tile {
    font-size: 13px !important;
    line-height: 18px !important;
  }

  .react-calendar__navigation__label__labelText {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .calendar-container {
    max-width: 350px;
  }

  .react-calendar__month-view__days__day {
    color: #666 !important;
    font-size: 14px !important
  }

  /* Chat  */
  .chat .chat-sidebar form input {
    padding: 0.45rem;
  }

  .chat .chat-sidebar form span {
    font-size: 14px;
    top: 0.4rem;
    right: 0.8rem;
  }

  .chat .chat-sidebar .users .user {
    gap: 0.5rem !important;
    padding: 0.6rem !important;
  }

  .chat .chat-sidebar .users .user h4 {
    font-size: 14px;
    font-weight: 600;
  }

  .chat .chat-sidebar .users .user .user-img img {
    width: 30px;
    height: 30px;
  }

  .chat .chat-sidebar form {
    width: 99%;
  }

  .chat .chat-body .chat-content p {
    font-size: 14px;
  }

  .sellerdashboard-content .right .right-sec3 .active-products .product-content h5,
  .sellerdashboard-content .right .right-sec3 .active-products .product-content .price,
  .user-das-content .right .right-sec3 .wishlist .product-content a {
    font-size: 14px;
  }



}


@media(max-width: 460px) {

  .sellerdashboard-content .hpoints-graph>div:first-child .card {
    width: 47% !important;
  }





}

@media(max-width: 416px) {
  .user-das-content .right .right-sec2 .table-div {
    max-width: 300px !important;
  }

  .hpoints-graph .graph {
    flex-direction: column;
    text-align: center;

  }

  .user-das-content .hpoints-graph .graph-content .user-photo span {
    right: 37%;
  }

  /* .user-das-content .hpoints-graph .card {
    width: 47%;
  } */

  .user-das-content .right .right-sec3 .wishlist>div:not(:first-child),
  .user-das-content .right .right-sec3 .recp-search>div {
    flex-direction: column;
    gap: 0.6rem;
    border-bottom: 1px solid #EEE;
    padding-bottom: 0.3rem;
    margin-bottom: 1rem !important;
  }

  .user-das-content .right .right-sec3 .recp-search>div .product-content .view {
    padding-top: 0 !important;
  }

}

/* End Media Query ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */