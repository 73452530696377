/* Start Build My startup services (BMSs)  */
.build-ms-services .cards-container {
    gap: 1rem;
}

.build-ms-services .cards-container .card {
    width: 32%;
    border: none;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.02);
}

.build-ms-services .cards-container .card h4 {

    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* End Build My startup services   */


.build-ms-form .form-div .stage {
    width: 100%;
    background: #1351C1;
}

.build-ms-form .form-div .stage span {
    text-align: center;
    padding: 0.8rem 0rem;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    display: inline-block;
    width: calc(100% / 3);
    height: 100%;
}
.build-ms-form .form-div .stage span:not(:last-child) {
 border-right: 2px solid #fff;
}

.build-ms-form .form-div .stage span.active {
    background: #FFAE00;
    color: #000;
    border-right: none;

}

.build-ms-form .form-div form input,
.build-ms-form .form-div form .company-confirm,
.build-ms-form .form-div form  select {
    border: none;
    outline: none;
    padding: 1rem;
    font-size: 16px;
    width: 48%;
}

.build-ms-form .form-div form  .alert-para{
    display: none;
}
.build-ms-form .form-div form input.full-address {
    width: 100%
}



.build-ms-form .form-div form button {
    background: #1351C1;
    color: #fff;
    padding: 12px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 2px;
}



/* End Build My Startup Form==================== */

/* Recent Startup   */
.recent-startup-list .recent-stp {
    overflow-x: auto;
}
.recent-startup-list .recent-stp  .cards {
    min-width: 1080px;
}


/* Start Chart  */
.build-ms-chart .rounded-div {
    width: 116px;
    height: 88px;
    background: rgba(19, 81, 193, 0.12);
    border-radius: 0 0 2rem  2rem;
    margin-bottom: 2rem;
}

.build-ms-chart .left-content ul li {
    color: #555;
    font-size: 18px;
    font-weight: 500;
    padding: 1rem 1.2rem;
    border-radius: 0.5rem;

}

.build-ms-chart .left-content ul li span{ 
    background-color: #ffffff09;
  
}

/* .build-ms-chart .left-content ul li .arrow-icon { 
    display: none;
} */

.active-build-ms-stab{
    background: #fff ;
    color: #1754c2 !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.08);
    scale: 1.1;
}


/* right-contnet */
.build-ms-chart .right-content {
    background-color: #EDEDFB;
}

.build-ms-chart .right-content > div .bold-para {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

/* End Chart  */




/* Start Media Query   */
@media (max-width:1199px) {
    .build-ms-form .form-div .stage span {
        font-size: 14px;
    }
}

@media(max-width: 992px) {
    .build-ms-services .cards-container .card {
        width: 48%;
    }
    .build-ms-chart .rounded-div {
        width: 90px;
        height: 60px;
    }
}


@media(max-width:767px) {
    .build-ms-chart .right-content > div .bold-para {
        font-size: 14px;
    }
}
@media(max-width:460px) {
    .build-ms-services .cards-container .card {
        width: 99%;
    }

    .build-ms-form .form-div .stage span {
        font-size: 12px;
        padding: 0.5rem 0;
    }

    .build-ms-form .form-div form input,
    .build-ms-form .form-div form .company-confirm {
        width: 99%;
    }

    .build-sm-benefits .cards > div {
        flex-direction: column;
        gap: 1rem;
    }


}
.build-ms-comp2{
    background-color: #fff;
    padding: 2rem !important;
}

/* End Media Query   */