@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap');
body {
    font-size: 14px !important;
    /* font-family: "Poppins", sans-serif; */
    /* font-family: 'Rubik', sans-serif !important; */
    font-family: 'Inter', sans-serif;
    color: #000 !important;
    /* background: #E7E7E7; */
}

.mt-1 {
    margin-top: 5px;
}

.mt-2 {
    margin-top: 10px;
}

.mt-3 {
    margin-top: 15px;
}

.mt-4 {
    margin-top: 20px;
}

.mt-5 {
    margin-top: 25px;
}

.mt-6 {
    margin-top: 30px;
}

.ml-1 {
    margin-left: 5px;
}

.ml-2 {
    margin-left: 10px;
}

.ml-3 {
    margin-left: 15px;
}

.ml-4 {
    margin-left: 20px;
}

.ml-5 {
    margin-left: 25px;
}

.ml-6 {
    margin-left: 30px;
}

.mr-1 {
    margin-right: 5px;
}

.mr-2 {
    margin-right: 10px;
}

.mr-3 {
    margin-right: 15px;
}

.mr-4 {
    margin-right: 20px;
}

.mr-5 {
    margin-right: 25px;
}

.mr-6 {
    margin-right: 30px;
}

.pd-1 {
    padding-top: 5px;
}

.pd-2 {
    padding-top: 10px;
}

.pd-3 {
    padding-top: 15px;
}

.pd-4 {
    padding-top: 20px;
}

.pd-5 {
    padding-top: 25px;
}

.pd-6 {
    padding-top: 30px;
}

.pd-left-1 {
    padding-left: 5px;
}

.pd-left-2 {
    padding-left: 10px;
}

.pd-left-3 {
    padding-left: 15px;
}

.pd-left-4 {
    padding-left: 20px;
}

.pd-left-5 {
    padding-left: 25px;
}

.pd-left-6 {
    padding-left: 30px;
}

.pd-right-1 {
    padding-right: 5px;
}

.pd-right-2 {
    padding-right: 10px;
}

.pd-right-3 {
    padding-right: 15px;
}

.pd-right-4 {
    padding-right: 20px;
}

.pd-right-5 {
    padding-right: 25px;
}

.pd-right-6 {
    padding-right: 30px;
}

a {
    /* color: #ff0000 !important; */
    text-decoration: none !important;
}

img {
    max-width: 100% !important;
}

.seller-header-topmenu {
    margin-right: 25px;
}

.seller-contact-details {
    display: flex;
    align-items: baseline;
}

.reviews {
    display: flex;
}

.ratings-stars {
    margin-right: 5px;
}


/* .apna-pro-bg {
    border-radius: 9px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.10);
    padding: 10px;
} */

.feedback-head {
    font-size: 36px;
    text-align: center;
    margin: 20px 0px;
}

.about-accordian .accordion {
    margin-bottom: 15px !important;
}

.review-img {
    width: 40px;
    margin-right: 27px;
}

.seller-contact-details .fa-map-marker {
    font-size: 22px;
    color: #ffb800;
}

.seller-contact-details .fa-mobile {
    font-size: 20px;
    color: #ffb800;
}

.seller-contact-details .fa-whatsapp {
    font-size: 17px;
    color: #ffb800;
}

.seller-pd-1 {
    padding: 20px;
}

.seller-contact-details .fa-envelope {
    color: #ffb800;
}

.seller-contact-details p {
    font-size: 18px;
    margin-left: 10px;
}

.form-control {
    border-radius: 0 !important;
    min-height: 40px;
}

.form-control:focus {
    box-shadow: none !important;
}

.full-btn {
    width: 100%;
    display: block;
}

.mt-8 {
    margin-top: 80px;
}

.btn {
    min-height: 30px;
    padding: 7px 15px;
    border-radius: 4px;
}

.brand-and-sponser {
    display: flex;
    justify-content: space-between;
}

.btn:focus {
    box-shadow: none !important;
}

.btn-primary {
    background: #ffb800;
    border: 1px solid #ffb800;
    color: #000 !important;
}

.btn-primary:hover {
    background: #ffb800;
    border: 1px solid #ffb800;
    color: #fff !important;
}

.btn-primary:focus {
    box-shadow: none;
}

.mobile-hidden {
    display: none;
}

.btn-secondary {
    color: #fff !important;
    background-color: #3979da !important;
    border-color: #3979da !important;
}

.btn-secondary:hover {
    color: #fff !important;
    background-color: #3979da !important;
    border-color: #3979da !important;
}

.btn-black {
    color: #fff !important;
    background-color: #000 !important;
    border-color: #000 !important;
}

.btn-black:hover {
    color: #fff !important;
    background-color: #000 !important;
    border-color: #000 !important;
}

.form-select:focus {
    box-shadow: none;
}

.package-border {
    border-top: 1px solid lightgray;
    /* margin-top: 5px;
  margin-bottom: 20px; */
}

.seller-header-mobile {
    display: none;
}

.product-detail-prodname {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.product-detail-prodetail {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #000000;
}

.stock-blk-new {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #4C9700;
}

.seller-header-margin {
    margin-right: 10px;
}


/* 
.container-fluid {
    padding: 0 20px !important;
} */

.topmenublk ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: right;
}

.topmenublk ul li {
    display: inline-block;
    font-weight: 600;
}

.topmenublk ul li img {
    margin-left: 10px;
}


/* .topmenublk ul li span {
    color: #FFB800;
    position: relative;
    right: 6px;
    bottom: 6px;
    font-weight: 700;
} */

.language-dropdown {
    border: none;
    /* width: 90%; */
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 5px;
    cursor: pointer;
}

.dropbtn {
    color: #000;
    padding: 10px;
    font-size: 16px;
    border: none;
}

.dropbtn img {
    margin-left: 5px;
}

.seller-header-desktop {
    display: block;
}


/* .select-location {
    display: flex;
    position: relative;
    left: 30px;
} */

.select-location button {
    padding: 5px;
    border-radius: 25px;
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    top: 5px;
}

.select-location button img {
    margin-right: 0px;
}

.header-login {
    padding: 0 !important;
    display: inherit !important;
}

.select-location span {
    position: relative;
    /* background: #FFB800;
    padding: 3px 20px;
    border-radius: 20px;
    margin-left: 20px; */
}

.prefer-location span img {
    max-width: none !important;
}

.prefer-location-button {
    padding: 5px;
    border-radius: 5px;
    background: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid lightgray;
    margin-top: 10px;
    width: 95%;
    margin-bottom: 10px;
    display: flex;
}

.location-input {
    width: 100%;
    border: none;
    font-size: 12px;
    margin-left: 0px;
    position: relative;
    left: -6px;
    background: none;
}

.location-input::placeholder {
    font-weight: 600;
    padding-left: 15px;
}

.prefer-location button img {
    margin-right: 0px;
}

.prefer-location span {
    position: relative;
    /* background: #FFB800;
    padding: 3px 10px;
    font-size: 12px;
    border-radius: 20px; */
    margin-left: 20px;
}

.prefer-location span img {
    max-width: none !important;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    color: #000;
    border: none;
    cursor: pointer;
    font-weight: 600;
}

.size-dropdown {
    padding: 10px;
    border: 1px solid;
    margin-top: 10px;
    border-radius: 5px;
    width: 70%;
}

.size-dropdown h6 {
    font-family: Inter, "sans-serif";
    font-size: 15px;
    font-weight: 600;
    color: #000;
    margin: 0px;
}

.dropdown-menu-1 {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    z-index: 999;
    border-radius: 4px;
}

.dropdown-menu-1 li {
    font-size: 14px !important;
    cursor: pointer;
}

.dropdown-menu-1 ul {
    text-decoration: none !important;
    list-style-type: none;
    margin-bottom: 0rem;
    padding-left: 0rem;
}

.pop-location {
    font-weight: 600;
    margin-top: 10px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;
    text-align: left;
    padding-left: 15px;
}

.pop-locations {
    font-weight: 600;
    margin-top: 10px;
    padding-bottom: 5px;
    text-align: left;
    font-size: 15px;
    padding-left: 15px;
    cursor: pointer;
}

.prefer-location-container {
    padding-bottom: 20px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.search-dropdown-content {
    position: absolute;
    /* top: 110%; */
    left: 0;
    width: 100%;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.onclick-dropdown {
    background: #fff;
    width: 100%;
    z-index: 1000px !;
    position: absolute;
    z-index: 1000;
}

.dropdown-service-list ul {
    list-style-type: none;
    padding: 20px;
    max-height: 300px;
    overflow-y: scroll;
}

.all-categories-link {
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #000000;
    cursor: pointer;
}

.all-categories-link i {
    margin-right: 10px;
}

.post-free-btn {
    position: absolute;
    right: 0px;
    margin-right: 25px;
}

.chat-now-btn {
    width: 43%;
    background: #258D35 !important;
    border-radius: 3px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 10px !important;
    margin: 10px 10px !important;
}

.chat-now-btn img {
    margin-right: 10px;
}

.call-now-btn img {
    margin-right: 10px;
}

.call-now-btn {
    width: 44%;
    background: #258D35 !important;
    border-radius: 3px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 10px !important;
    margin: 10px 10px !important;
}

.send-email-btn2 button {
    width: 93%;
    border: 1px solid #0085FF;
    border-radius: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #0085FF !important;
    background: none !important;
    margin: 0px 10px 10px 10px !important;
    padding: 8px;
    border-radius: 3px !important;
}

.send-email-btn2 button img {
    margin-right: 10px;
}

.post-free-btn button {
    background: #000;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #fff;
    padding: 10px 25px;
}

.dropdown-service-list ul li {
    padding: 7px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #555555;
    cursor: pointer;
    border-bottom: 1px solid #C4C4C4;
}

.dropdown-service-list ul li:hover {
    border-bottom: 1px solid #f8bb21;
}

.dropdown-top-border {
    border-top: 1px solid #C4C4C4;
}

.dropdown-right-border {
    border-right: 1px solid #C4C4C4;
}

.dropdown-right-section {
    padding: 15px;
}

.dropdown-right-section h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #000000;
}

.dropdown-right-section ul {
    list-style-type: none;
    padding: 0px;
    display: flex;
}

.dropdown-right-section ul li {
    margin-right: 50px;
}

.dropdown-right-section ul li img {
    margin-right: 5px;
}

.our-clients-cols {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.6rem;
}

.clients-info-section {
    display: grid;
    /* grid-template-columns: 19% 19% 19% 19% 18%; */
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
}

.blog-grid-block-content h1 {
    font-size: 20px;
    text-align: justify;
}

.blog-grid-block-content h2 {
    font-size: 17px;
    text-align: justify;
    margin-top: 15px;
}

.blog-grid-block-content h3 {
    font-size: 16px;
    text-align: justify;
    margin-top: 15px;
}

.blog-grid-block-content h4 {
    font-size: 16px;
    text-align: justify;
    margin-top: 15px;
}

.blog-grid-block-content h5 {
    font-size: 16px;
    text-align: justify;
    margin-top: 15px;
}

.blog-grid-block-content h6 {
    font-size: 16px;
    text-align: justify;
    margin-top: 15px;
}

.city-bg-img {
    background: url(../public/assets/images/city-bg.png);
    border-top: 1px solid #C4C4C4;
    box-shadow: -3px 20px 10px -19px #111;
}

.dropdown-contact-section {
    display: flex;
    justify-content: space-around;
    padding: 50px 0px;
}

.dropdown-right-section button {
    margin: 5px;
    position: relative;
    right: 85px;
    border-radius: 20px !important;
    background-color: #f8bb21 !important;
    color: black;
    border: none;
}

.dropdown-right-section .input-group {
    width: 60%;
    margin: 15px 4px 15px 1px !important;
}

.dropdown-right-section input {
    border-radius: 20px !important;
}

.dropdown-contact-section p {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;
}

.dropdown-contact-section h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.01em;
    color: #FF9900;
}

.chat-btn {
    background: #FFB800;
    border-radius: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    padding: 5px 5px 5px 17px;
    margin-bottom: 20px;
}

.chat-btn img {
    background: #fff;
    padding: 4px 10px;
    border-radius: 15px;
    margin-left: 75px;
}

.sell-aladdin-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    background: #000000;
    border-radius: 50px;
    padding: 5px 5px 5px 16px;
}

.sell-aladdin-btn img {
    background: #fff;
    padding: 4px 10px;
    border-radius: 15px;
    margin-left: 15px;
}

.dropdown-service-list ul li img {
    margin-right: 10px;
    width: 20px;
}

.sub-category-mr {
    margin: 10px 0px;
}

.sub-dropdown-filter {
    max-height: 200px;
    overflow-y: scroll;
}


/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */

.topmenublk ul li a {
    display: block;
    padding: 10px 7px;
    color: #000 !important;
}

.margin-right-1 {
    margin-right: 10px;
}

.topmenublk ul li:last-child a {
    padding-right: 0;
}

.welcome-text {
    margin: 0;
    padding: 10px 0;
}

.footer-links a {
    color: white;
}

.footer-links a:hover {
    color: #0085FF;
}


/* .searchheade-block {
    background: #fff;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
} */

.logo-searchbar-block {
    background: #000;
    border-radius: 5px;
    padding: 10px;
}


/* .search-bar-width {
    width: 600px;
} */


/* .searchheade-block .input-group {
    border: none;
} */


/* .searchheade-block .input-group .input-group-text {
    background: transparent;
    border: none;
    width: 150px;
    font-weight: 600;
}

.searchheade-block .form-select {
    border: none;
    font-size: 14px;
    font-weight: 600;
}

.searchheade-block .form-control {
    border: none;
    border-left: 1px solid #ddd;
    font-size: 14px;
    min-height: 35px !important;
    font-weight: 600;
} */

.kyc-verification h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    text-align: center;
    font-weight: 700;
}

.details-verify p {
    margin-bottom: 0.2rem !important;
}

.details-verify hr {
    margin: 0rem 0 0rem 0rem !important;
}

.details-verify {
    margin-top: 2rem !important;
}

.details-name {
    color: gray;
}

.payment-info {
    font-size: 14px !important;
    margin-top: 5px;
}

.payment-mr {
    margin-top: 30px;
}

.kyc-verification h6 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
}

.thankyou-section {
    width: 500px;
    margin: 40px auto;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 15px 45px rgb(134 160 174 / 15%);
    border-radius: 4px;
    padding: 20px;
}

.thankyou-section h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}

.thankyou-section h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
}

.thankyou-section p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.thankyou-section h6 {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.thankyou-pera {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.thankyou-content {
    display: flex;
    justify-content: space-between;
}

.thankyou-btn button {
    border: 1px solid #eee;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    background: #2c83ca;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.thankyou-btn button {
    border: 1px solid #eee;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    background: #2c83ca;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.thankyou-btn {
    text-align: center;
}

.thankyou-hr {
    margin-bottom: 40px;
}

.thankyou-btn {
    text-align: center;
}

.thankyou-hr {
    margin-bottom: 40px;
}

.kyc-verification p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
}


/* .searchheade-block .btn-search {
    background: #1351C1 !important;
    padding: 8px 25px !important;
    border-radius: 30px !important;
    margin-left: 5px !important;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  
} */

.searchheade-block .btn-search {
    padding: 0.6rem 0.1rem;
    margin-right: 0.3rem;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.search-img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.search-products {
    display: flex;
    padding: 3px 10px;
}

.search-products:hover {
    background-color: #f3f3f3;
}

.search-products p {
    margin: 0rem 0rem 0.1rem 0.4rem !important;
}

.logo-block img {
    width: 100%;
    height: 60px;
}

.cartlogin-block ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.cartlogin-block ul li {
    display: inline-grid;
    width: 50%;
    text-align: center;
}

.sell-aladin-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #000000 !important;
    letter-spacing: 0.01em;
    margin-right: 15px;
}

.sell-aladin-btn span {
    background: #fff;
    padding: 4px 15px;
    border-radius: 23px;
    margin: 0px -8px 0px 11px;
}

.cartlogin-block ul li a {
    display: block;
    position: relative;
    font-size: 23px;
    color: #fff !important;
    padding: 5px 15px;
}

.cartlogin-block ul li.fav-menu-block a span,
.cartlogin-block ul li.cart-menu-block a span {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 11px;
    text-align: center;
    border-radius: 30px;
    padding: 2px 0;
    width: 20px;
    height: 20px;
    background: #ffb800;
    color: #000;
}

.all-department-menu {
    background: #ffb800;
}

.all-department-menu .dropdown-toggle::after {
    content: none;
}

.all-department-menu .nav-link.dropdown-toggle {
    padding: 10px 15px;
    color: #000 !important;
    font-size: 17px;
    font-weight: 500;
}

.all-department-menu .nav-link.dropdown-toggle i {
    font-size: 16px;
    margin-right: 10px;
}

.all-department-menu .dropdown-menu {
    width: 100%;
}

.all-department-menu .dropdown-menu .dropdown-item {
    color: #000 !important;
    padding: 6px 12px;
}

.all-department-menu .dropdown-menu .dropdown-item i {
    float: right;
}

.all-department-menu .dropdown-menu .dropdown-item:active {
    background-color: inherit !important;
}

.mainmenu-block .nav-link.dropdown-toggle:after {
    content: "\f282";
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -0.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: none;
    font-size: 13px;
}

.mainmenu-block .navbar {
    padding: 0;
}

.mainmenu-block .navbar .nav-link {
    color: #000 !important;
    padding: 10px 8px !important;
    font-size: 15px;
    font-weight: 600;
}

.mainmenu-block .navbar .nav-link.active,
.mainmenu-block .navbar .nav-link:hover {
    color: #ffb800 !important;
}

.navbar-nav .dropdown-menu {
    position: absolute !important;
}

.dropdown-item {
    color: #000 !important;
    font-size: 14px;
    font-weight: 300;
}

.dropdown-item:active {
    background-color: #ffb800 !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff !important;
    text-decoration: none;
    background-color: #ffb800;
}

.bottom-header-section {
    padding: 8px 0;
}

.header-section {
    position: sticky;
    background: #fff;
    z-index: 999;
    top: 0px;
}


/* .banner-section img {
    width: 100%;
} */

.instruc-img-blk {
    width: 70px;
    height: 70px;
    background: #f5f5f5;
    text-align: center;
    padding: 20px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    margin: auto;
    padding-bottom: 15px;
}

.instruc-cont-blk {
    width: calc(100% - 70px);
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
}

.instruc-cont-blk h4 {
    font-size: 16px;
    margin: 0 0 0px;
}

.center {
    text-align: center;
    text-align: -webkit-center;
}

.instruc-cont-blk p {
    margin: 0;
    font-size: 14px;
    color: #666666;
}

.instruction-blk {
    margin: 0 0 30px;
    text-align: center;
}

.instrusction-block {
    /* margin: 30px 0 0; */
}

.caetfory-small-block {
    text-align: center;
    width: 155px;
    height: 160px;
    margin-left: 10px;
    margin-right: 10px;
    /* margin: 0 0 50px; */
}

.caetfory-small-block img {
    width: 180px;
    height: 100px;
    /* object-fit: contain; */
}

.caetfory-small-block h5 {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    padding: 5px 0 0 0;
}

.caetfory-small-block {
    text-align: center;
    margin: 0;
    background: #fff;
    padding: 10px 10px 15px;
    border-radius: 2px;
}

.category-main-blk {
    background: #eeeeee;
    padding: 20px;
}

.black-friday-sales-section {
    padding: 20px 0;
}

.bfs-offer-img img {
    width: 100%;
}

.bfs-offer-img {
    position: relative;
    height: 100%;
    background-size: cover !important;
}

.bfs-cont-blk {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
}

.bfs-cont-blk h5 {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    margin: 0 0 10px;
}

.bfs-cont-blk h3 {
    font-weight: bold;
    font-size: 30px;
    color: #fff;
    margin: 0 0 10px;
}

.bfs-cont-blk h3 span {
    color: #1351C1;
}

.bfs-cont-blk h4 {
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    margin: 0 0 10px;
}

.btn-shop-white {
    background: #1351C1 !important;
    text-transform: uppercase;
    /* padding: 5px 13px; */
    color: #fff !important;
    font-weight: normal;
    font-size: 14px;
    min-height: 35px !important;
}


/* .btn-shop-now {
  background: #FFB800 !important;
  text-transform: uppercase;
  
  color: #000 !important;
  font-weight: normal;
  font-size: 14px;
} */

.bfs-3blk-img img {
    width: 100%;
}

.bfs-3blk-img {
    display: block;
    margin: 0 0 20px;
}

.super-deal-bfs-blk {
    border: 2px solid #ffb800;
    padding: 20px;
}

.super-deal-bfs-img img {
    width: 100%;
}

.product-img-blk {
    position: relative;
    margin: 0 0 10px;
}

.product-img-blk img {
    /* width: 100%; */
    /* min-width: 250px;
    min-height: 250px;
    width: 100%;
    height: 100%; */
}

.product-img-blk .sale-blk {
    position: absolute;
    background: #ff0000;
    color: #fff;
    padding: 2px 7px;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 15px;
    top: 0;
    right: 0;
}

.product-block h4 {
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #111111;
    margin: 0 0 10px;
    height: 34px;
    overflow: hidden;
}

.star-rate-show {
    text-align: center;
    color: #ffb800;
    margin: 0 0 10px;
}

.star-rate-show i {
    padding: 4px;
    font-size: 14px;
}

.product-block p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #1351C1;
    margin: 0;
    letter-spacing: -1px;
    padding-bottom: 8px;
}

.product-block {
    background: #ffffff;
    padding: 15px;
    text-align: center;
}

.product-block:hover {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 13%);
}

.new-league-block {
    text-align: center;
    padding: 40px 0;
    background-size: cover !important;
    min-height: 365px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.league-cont-blk h5 {
    font-weight: normal;
    font-size: 18px;
    color: #fff;
    margin: 0 0 10px;
}

.league-cont-blk h3 {
    font-weight: bold;
    font-size: 40px;
    color: #fff;
    margin: 0 0 10px;
}

.league-cont-blk p {
    color: #fff;
    margin: 0 0 20px;
}

.feature-blkpro-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 70px 10px 0;
    position: relative;
    margin-bottom: 20px;
}

.feature-blkpro-header:before {
    content: "";
    width: 85px;
    height: 3px;
    background: #1351c1;
    position: absolute;
    bottom: -2px;
}

.vendor-product {
    display: flex;
    width: "60%";
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: "50px";
    margin: 22px 0 0 0;
    border-bottom: 1px solid #eeeeee;
}

.feature-blkpro-header h4 {
    color: #000;
    font-family: Inter;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* margin-bottom: 1.5rem !important; */
}

.feature-blkpro-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.feature-blkpro-header ul li {
    display: inline-block;
    padding: 3px 15px;
}

.slider-section .owl-nav {
    position: absolute;
    right: 0;
    margin: 0;
    top: -3.3rem !important;
}

.products-nr .slider-section .owl-nav {
    top: -4.3rem !important;
}

.slider-section .owl-carousel .owl-nav .owl-prev,
.slider-section .owl-carousel .owl-nav .owl-next {
    background: #ffc120;
    width: 24px;
    color: #fff;
    font-size: 18px;
    margin: 0 0 0px 6px;
    border-radius: 0;
}

.feat-pro-img {
    display: inline-block;
    width: 100px;
    vertical-align: top;
}

.feat-pro-img img {
    max-width: 84px;
    max-height: 95px;
}

.fea-pro-cont {
    width: calc(100% - 100px);
    display: inline-block;
    vertical-align: top;
    padding: 10px;
}

.fea-pro-cont h3 {
    font-weight: normal;
    font-size: 14px;
    margin: 0 0 5px;
    color: #000;
    height: 34px;
    overflow: hidden;
}

.fea-pro-cont p {
    margin: 0;
    letter-spacing: -2px;
}

.fea-pro-cont .star-rate-show {
    text-align: left;
    margin: 0 0 5px;
}

.fea-pro-cont .star-rate-show i {
    padding-right: 0px;
    font-size: 10px;
}

.feature-pro-blk {
    border: 1px solid #eeeeee;

    margin: 0 0 20px;
}

.elec-cos-blk {
    min-height: 180px;
    background-size: cover !important;
    display: flex;
    padding: 30px;
    align-items: center;
    position: relative;
}

.elec-cos-blk::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0008ff38;
}

.elctro-blk h4 {
    font-weight: bold;
    font-size: 24px;
    color: #fff;
    margin: 0;
}

.elctro-blk p {
    font-size: 16px;
    color: #fff;
    margin: 0 0 5px;
}

.elctro-blk a {
    font-weight: 500;
    font-size: 16px;
    color: #fff !important;
    text-transform: uppercase;
}

.elctro-blk p span {
    color: #ffc120;
}

.elctro-blk.cosmatic-blk h4 {
    color: #111111;
}

.elctro-blk.cosmatic-blk p {
    color: #555555;
}

.elctro-blk.cosmatic-blk p span {
    color: #fe2424;
}

.elctro-blk.cosmatic-blk a {
    color: #fff !important;
}

.feature-product-section {
    padding: 1rem 0;
}

.new-league-section {
    margin: 0 0 30px;
}

.electronic-cosmatic-section {
    margin: 0 0 30px;
}


/* start it services ------- */

.it-services .main-cont .top-heading h6 {
    color: #000;
    font-family: Inter;
    font-size: 27px;
    font-weight: 600;
    line-height: 28px;
    /* 127.273% */
}

.it-services .main-cont .top-heading a {
    color: #1351C1;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.it-services .main-cont .top-heading a {
    color: #1351C1;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-decoration: underline;
    cursor: pointer;
}

.it-services .scards .sprovider h5 {
    color: #000;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
    margin-bottom: 0;
}

.it-services .scards .sprovider p {
    color: #888;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.12px;
}

.it-services .scards .hpara {
    color: #000;
    font-family: Rubik;
    font-size: 13px;
    font-weight: 450;
    letter-spacing: 0.14px;
}

.it-services .scards .sp-rating .rating-digit {
    color: #FFF;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.12px;
    background: #258D35;
    padding: 0rem 0.5rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
}

.it-services .scards .sp-rating .rating-people {
    color: #D2D2D2;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.14px;
}

.it-services .scards .price-cont span {
    color: #555;
    font-family: Rubik;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.11px;
    text-transform: uppercase;
}

.it-services .scards .price-cont p {
    color: #000;
    font-family: Rubik;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    margin-bottom: 0;
}

.it-services .scards .price-cont p.dprice {
    color: #888;
}

.it-services .scards .price-cont .heart i {
    font-size: 30px;
    color: #C1C1C1;
}

.it-services .scards .card {
    border: 1px solid #EFEFEF;
    border-radius: 0.7rem;
    width: 24%;
}

.it-services .scards .card>img {
    max-width: 323.86px !important;
    max-height: 194.526px;
    border-radius: 0.9rem 0.9rem auto auto;
}

.it-services .scards .price-cont {
    margin-top: 1rem;
    border-top: 1px solid #EFEFEF;
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
}

.it-services .prf-client .card-body h5 {
    color: #000;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.it-services .prf-client .card-body p {
    color: #555;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
}

.it-services .prf-client .card-body a {
    color: #1351C1;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}


/*end it services ------- */

.product-margin-carousel {
    padding: 0;
    margin: 10px;
    border: 1px solid rgba(128, 128, 128, 0.178);
}

.product-margin-carousel .product-cont-blk {
    padding: 0 10px 10px;
}

.clearence-dresswere-img {
    height: calc(100% - 20px);
    background-size: 100% 100% !important;
    margin: 10px 0;
}

.deal-pro-blk {
    padding: 30px;
}

.deal-pro-blk h5 {
    font-weight: 300;
    font-size: 16px;
    color: #000;
    margin: 0 0 10px;
}

.deal-pro-blk h3 {
    font-weight: normal;
    font-size: 24px;
    margin: 0 0 10px;
    color: #000;
}

.deal-pro-blk h3 span {
    font-weight: bold;
}

.deal-pro-blk a {
    font-weight: 300;
    font-size: 16px;
    color: #000 !important;
}

.view-all-btn button {
    color: red !important;
    font-size: 16px;
    font-weight: 600;
}

.product-img-blk img {
    height: 200px;
}

.view-all-btn {
    text-align: center;
    padding-bottom: 20px;
}

.home-smart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
}

.home-smart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
}

.home-smart-header:before {
    content: "";
    position: absolute;
    /* top: 27px; */
    left: 0;
    width: 3px;
    height: 70px;
    background: #000;
}


/* .home-smart-header a {
    color: #888888 !important;
} */

.homeinteri-img {
    height: 100%;
    background-size: 100% 100% !important;
}

.home-smart-block {
    background: #ffffff;
    box-shadow: 0px 0px 19px rgb(0 0 0 / 15%);
}

.home-smart-header h4 {
    font-weight: normal;
    font-size: 24px;
    margin: 0;
}

.product-home-smart {
    border-top: 1px solid #eeeeee;
    padding: 20px 10px;
}

.brand-block {
    background: #ffffff;
    border: 1px solid #eeeeee;
    padding: 20px;
    height: 200px;
}

.currency-dropdown {
    position: relative;
    right: 20px;
}

.brand-block img {
    /* width: 30px; */
    height: 150px;
    /* padding: 30px 0 0 0; */
}

.blog-grid-block {
    margin: 0 0 30px;
    border: 1px solid #f3f3f3;
    padding: 10px;
    border-radius: 8px;
}

.blog-grid-block-content {
    width: 100%;
    overflow: hidden;
}

.blog-grid-block-content p span {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px !important;
}

.blog-grid-block img {
    width: 100%;
    /* width: 300px !important; */
    height: 200px !important;
    height: 100%;
    margin: 0 0 10px;
}

.blog-grid-block h3 {
    color: #000;
    font-weight: normal;
    font-size: 22px;
    margin: 0 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.blog-grid-block p {
    font-weight: normal;
    font-size: 14px;
    color: #888888;
    margin: 0 0 10px;
    text-align: justify;
}

.blog-grid-block a {
    color: #ff0000;
}

.top-footer {
    background: #000;
    padding: 60px 0;
}

.top-foot-menu-blk {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    padding-bottom: 30px;
}

.foot-menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    /* display: flex; */
}

.foot-menu-list li a {
    display: block;
    padding: 8px 30px 0 0;
    font-size: 15px;
    color: #fff !important;
}

.footer-contact-list {
    padding: 10px 0 0 0;
}

.footer-contact-list span {
    /* display: block; */
    padding: 0 0 0 15px;
    font-size: 15px;
    color: #fff !important;
}

.footer-call-list {
    padding: 8px 0 0 0;
}

.footer-call-list span {
    /* display: block; */
    padding: 0 0 0 15px;
    font-size: 15px;
    color: #fff !important;
}

.footer-mail-list span {
    /* display: block; */
    padding: 0 0 0 20px;
    font-size: 15px;
    color: #fff !important;
}

.footer-mail-list {
    padding: 8px 0 0 3px;
}

.foot-social-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.foot-social-list li a {
    display: block;
    padding: 20px 0 0 12px;
}

.foot-social-list li a img {
    height: 40px;
}

.bottom-foot-menu-blk {
    padding-top: 30px;
}

.copyright-foot {
    color: white;
    padding: 10px 0 0 0;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
}

.inner-pages-wrapper {
    background: #f5f5f5;
    /* padding: 30px 0; */
}

.breadcrumb-block {
    padding: 0;
    margin: 0;
    list-style: none;
}

.breadcrumb-block li {
    display: inline-block;
}

.breadcrumb-block li {
    display: inline-block;
    padding: 0 10px 0 0;
    color: #111111;
}

.breadcrumb-block li a {
    display: block;
    position: relative;
    padding: 0 15px 0 0;
    color: #111111 !important;
}

.breadcrumb-block li a:before {
    content: "\203A";
    position: absolute;
    right: 0;
    font-size: 18px;
    top: -1px;
}

.breadcrumb-sction {
    /* margin: 0 0 30px; */
}

.inner-page-banner-section {
    padding: 0 0 30px;
}

.inner-banner-blk {
    background: #fff;
    padding: 15px;
}

.top-footer p span {
    background-color: rgb(43, 55, 59) !important;
}

.inner-banner-blk img {
    width: 100%;
}


/* Login CSS */

.custom-login-modal .modal-dialog {
    max-width: 370px;
}

.heading-title-blk {
    text-align: center;
    position: relative;
}

.heading-title-blk .btn-close {
    position: absolute;
    right: -10px;
    top: -10px;
}

.heading-title-blk h3 {
    font-size: 22px;
    color: #000;
    margin: 0 0 10px;
}

.heading-title-blk p {
    font-size: 15px;
    margin: 0 0 10px;
}

.forgot-password-blk {
    text-align: right;
}

.or-block {
    text-align: center;
    padding: 20px 0;
    position: relative;
}

.or-block:before {
    position: absolute;
    border-bottom: 1px solid #ddd;
    bottom: 29px;
    left: 0;
    content: "";
    width: 100%;
}

.or-block span {
    background: #fff;
    position: relative;
    display: inline-block;
    padding: 0 6px;
}

.or-block2 {
    text-align: center;
    padding: 10px 0 10px 0px;
    position: relative;
}

.or-block2:before {
    position: absolute;
    border-bottom: 1px solid #ddd;
    bottom: 20px;
    left: 0;
    content: "";
    width: 100%;
}

.or-block2 span {
    background: #fff;
    position: relative;
    display: inline-block;
    padding: 0 6px;
}

.bottom-block {
    text-align: center;
}

.login-signup-section {
    background: #f5f5f5;
    padding: 40px 0;
}

.login-inner-block {
    background: #fff;
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px #e7e7e7;
}

.login-bottom-blk {
    text-align: center;
}

.login-bottom-blk p,
.login-bottom-blk h6 {
    margin: 20px 0 0;
}

.login-bottom-blk h5 {
    font-size: 15px;
    margin: 20px 0 0;
}

.innerpage-section {
    padding: 40px 0;
}

.signup-inner-block {
    background: #fff;
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px #e7e7e7;
}

.heading-title-block {
    text-align: center;
    margin: 0 0 30px;
}

.heading-title-block h3 {
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 5px;
}

.heading-title-block p {
    font-size: 16px;
    margin: 0;
}

.input-text {
    font-size: 11px;
    color: #ababab;
}

#partitioned {
    padding-left: 15px;
    letter-spacing: 42px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 220px;
    min-width: 220px;
}

#partitioned:focus {
    outline: none;
}

#divInner {
    left: 0;
    position: sticky;
}

#divOuter {
    width: 190px;
    overflow: hidden;
    margin: 20px auto;
}

.heading-title-blk h4 {
    font-size: 19px;
    font-weight: 600;
    margin: 0 0 5px;
}

.verification-new-section {
    max-width: 710px;
    margin: 0 auto;
}

.verification-layout {
    margin: 0 0 60px;
    position: relative;
}

.verification-layout:before {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 30px;
    height: 2px;
    background: #cd3161;
}

.verification-layout:last-child {
    margin: 0;
}

.verification-layout:last-child:before {
    content: none;
}

.verify-block h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 10px;
}

.verify-block p {
    font-size: 16px;
}

.verify-opt-block {
    display: flex;
}

.verify-opt-block .inline-button {
    border-radius: 0 !important;
}

.verify-opt-block {
    display: flex;
    margin: 0 0 15px;
}

.verify-block h6 {
    font-size: 15px;
    margin: 0;
}

.verification-image-blk {
    text-align: center;
}

.verification-image-blk img {
    max-width: 180px;
    margin: 0 0 20px;
}

.verification-image-blk h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 10px;
}

.verification-image-blk p {
    font-size: 15px;
}

.verify-button-blk {
    margin: 20px 0 0;
    text-align: right;
}

.verify-button-blk .btn-primary {
    width: 180px;
}

.card-block {
    background: #ffffff;
    box-shadow: 0px 0px 19px rgb(0 0 0 / 15%);
    margin: 0 0 30px;
}

.card-head-blk {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
    border-bottom: 1px solid #eeeeee;
}

.card-head-blk:before {
    content: "";
    position: absolute;
    /* top: 27px; */
    left: 0;
    width: 3px;
    height: 67px;
    background: #ffb800;
    /* background: #000; */
}

.flex {
    display: flex;
}

.card-head-blk h4 {
    font-weight: normal;
    font-size: 24px;
    margin: 0;
}

.card-body-blk {
    padding: 20px;
}

.contentWrapper {
    display: flex;
    overflow: hidden;
    width: 100%;
    /* border: 5px solid #ffffff; */
    /* border-radius: 10px; */
}

.faq-left .accordion {
    margin-bottom: 15px !important;
}

.border-boottom {
    border-bottom: 2px solid #f3f3f3;
}

.avon-search-input {
    background: #fff;
    position: absolute;
    z-index: 100;
    border-radius: 5px;
    padding: 10px 5px;
    margin-top: 2px;
    border: 1px solid #1351C1;
}

.faq-right .accordion {
    margin-bottom: 15px !important;
}

.custom-accordion .accordion-item {
    background: #f5f5f5;
    border: none;
    margin: 0 0 10px;
}

.custom-accordion .accordion-button {
    background: #f5f5f5;
    padding: 10px 15px;
    font-size: 14px;
}

.custom-accordion .accordion-button:focus {
    border-color: transparent;
    box-shadow: none;
}

.custom-accordion .accordion-button::after {
    width: 0.9rem;
    height: 0.9rem;
    background-size: 0.9rem;
}

.dashboard-dropdown-toggle {
    display: none;
}

.about-cont-block .card-body-blk {
    min-height: 300px;
}

.testimonial-block {
    text-align: center;
}

.testimonial-block p {
    font-weight: 300;
    font-size: 16px;
    margin: 0 0 15px;
}

.testimonial-block img {
    width: 100px !important;
    margin: 0 auto 15px;
    background: #ddd;
    height: 100%;
    border-radius: 50%;
}

.testimonial-block h5 {
    margin: 0;
}

.testimonial-block h5 a {
    display: block;
    font-size: 16px;
    font-weight: 300;
}

.testimonial-block h5 span {
    display: block;
    font-size: 14px;
    font-weight: 300;
}


/* .owl-testimonial-slider .owl-nav {
  position: absolute;
  top: -70px;
  right: 0;
  margin: 0 !important;
}
.owl-testimonial-slider.owl-carousel .owl-nav .owl-prev,
.owl-testimonial-slider.owl-carousel .owl-nav .owl-next {
  background: #ffc120;
  width: 24px;
  color: #fff;
  font-size: 18px;
  margin: 0 0 0px 6px;
  border-radius: 0;
} */

.form-group {
    margin: 0 0 20px;
}

.contact-info {
    padding: 0;
    margin: 0 0 30px;
    list-style: none;
}

.contact-info li {
    padding: 6px 0;
}

.contact-info li i {
    width: 25px;
    text-align: center;
    display: inline-block;
    color: #fe2424;
    vertical-align: middle;
}

.contact-info li span {
    width: calc(100% - 30px);
    display: inline-block;
    vertical-align: middle;
    font-weight: 300;
}

.contact-socialmedia {
    padding: 0;
    margin: 0;
    list-style: none;
}

.contact-socialmedia li {
    display: inline-block;
}

.contact-socialmedia li a {
    display: block;
    background: #eeeeee;
    color: #000 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    margin: 0 6px 0 0;
}

.sidebar-block {
    background: #fff;
    margin-bottom: 30px;
}

.side-head-blk {
    background: #ffb800;
    padding: 10px 15px;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
}

.side-accordian .accordion-item {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ebebeb;
}

.side-accordian .form-label {
    font-family: sans-serif;
    font-weight: 600;
}

.side-accordian .accordion-item .accordion-header .accordion-button {
    padding: 13px 15px;
    font-size: 16px;
}

.side-accordian .accordion-button:not(.collapsed) {
    color: #000;
    background: transparent;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.side-accordian .accordion-body {
    padding: 15px;
}

.customcheckbox {
    margin: 7px 0px !important;
}

.customcheckbox .form-check-label {
    display: block;
    cursor: pointer;
}

.customcheckbox .form-check-label span {
    float: right;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.side-accordian .accordion-button::after {
    width: 13px;
    height: 13px;
    background-size: 100%;
}

.shopsearch-grid-blk {
    padding: 10px;
    background: #fff;
    margin: 0 0 30px;
}

.gridlist-item-blk {
    display: flex;
    align-items: center;
}

.gridlist-btn {
    font-size: 17px;
    padding: 5px;
    background: #eeeeee;
    width: 33px;
    height: 33px;
    display: inline-block;
    text-align: center;
    color: #000 !important;
    margin-right: 10px;
    font-size: 18px;
}

.gridlist-btn.active {
    background: #ffb800;
    color: #fff !important;
}

.gridlist-item-blk span {
    margin-left: 10px;
}

.sortby-block {
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
    top: 5px;
}

.timer-section {
    text-align: center;
    margin-top: 10px;
}

.timer-section span {
    background-color: #FFB800;
    color: #000;
    padding: 3px 6px;
    font-weight: 600;
    margin-right: 5px;
}

.sortby-block .form-select {
    max-width: 160px;
    height: 33px;
    font-size: 13px;
}

.form-select:focus {
    box-shadow: none !important;
}

.shopheader-blk {
    margin: 0 0 10px;
}

.shopheader-blk h3 {
    font-size: 28px;
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;
}

.product-shoppage {
    margin: 0 0 30px;
}

.carousel .thumbs-wrapper {
    margin: 10px 0 0 !important;
}

.carousel .thumbs {
    padding: 0;
    margin: 0;
}

.carousel .thumb {
    width: 25% !important;
    margin: 0 !important;
}

.product-details-cont h3 {
    color: #000;
    font-size: 18px;
    margin: 0 0 10px;
}

.product-details-cont h5 {
    font-weight: 300;
    font-size: 14px;
    /* margin: 0 0 10px; */
    padding: 5px;
}

.proprice-blk {
    font-weight: 500;
    margin: 0 0 10px;
}

.product-details-cont h4 {
    font-size: 14px;
    margin: 0 0 10px;
    color: #4c9700;
}

.product-details-cont h4 span {
    margin-right: 20px;
}

.sku-blk b {
    font-weight: normal;
    color: #000;
}

.product-details-cont p {
    color: #888888;
    margin: 0 0 10px;
}

.qty-cart-fav-block {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.qty-plsminus-blk {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.plusminus-qty .input-group button {
    padding: 6px 8px;
    border: 1px solid #ddd;
    background: #fff;
    height: 40px;
}

.plusminus-qty .input-group input {
    /* padding: 6px 12px; */
    padding: 0 0 0 28px;
    background: #fff;
    border: 1px solid #ddd;
    max-width: 70px;
    -moz-appearance: textfield;
}

.plusminus-qty .input-group input:focus {
    outline: none;
}

.qty-plsminus-blk span {
    margin-right: 10px;
    font-size: 16px;
}

.qty-cart-fav-block .btn {
    margin-right: 10px;
    border-radius: 0;
}

.download-invoice {
    background: rgb(222, 40, 40) !important;
    color: #fff;
    border-radius: 8px;
}

.share-product {
    display: flex;
    align-items: center;
}

.socialshare-blk {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.socialshare-blk li a {
    background: #ffffff;
    border: 1px solid #eeeeee;
    height: 40px;
    width: 40px;
    display: block;
    margin-right: 10px;
    border-radius: 50%;
    text-align: center;
    line-height: 39px;
    color: #888 !important;
}

.socialshare-blk li a:hover {
    background: #ffb800;
    color: #fff !important;
    border: 1px solid #ffb800;
}

.share-product span {
    margin-right: 50px;
}

.product-details-main-block .card-block {
    margin: 0;
}

.productsdetails-tab {
    margin: 30px 0 0;
}

.productsdetails-tab .nav.nav-tabs {
    border: none;
    margin-bottom: 15px;
}

.productsdetails-tab .nav-tabs .nav-link {
    margin: 0 10px 0 0;
    background: #eee;
    border: none;
    border-radius: 0;
    color: #333;
}

.productsdetails-tab .nav-tabs .nav-link:focus {
    outline: none;
}

.productsdetails-tab .nav-tabs .nav-link.active {
    color: #fff;
    background: #ffb800;
}

.cartreview-block {
    margin: 0px 0 20px;
    padding: 0px;
    list-style: none;
}

.cartreview-block li {
    padding: 9px 0;
    border-bottom: 1px dashed #a4a3a3;
    font-size: 15px;
}

.cartreview-block li:last-child {
    border-bottom: none;
}

.cartreview-block li p {
    margin: 0;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
}

.coupon-code {
    position: relative;
    margin: 10px 0;
}

.coupon-code input {
    width: 100%;
    height: 35px;
    padding: 3px 50px 3px 10px;
    border: 1px solid #ddd;
    background: #f5f8ff;
    text-transform: uppercase;
    font-size: 12px;
}

.coupon-code input:focus {
    outline: none;
}

.addcode-btn {
    position: absolute;
    right: 0;
    top: 0;
    color: #000;
    text-transform: uppercase;
    background: #f8bc22;
    height: 35px;
    padding: 9px 10px;
    font-size: 13px;
    font-weight: bold;
    color: #000 !important;
}

.cartreview-block li b {
    font-weight: normal;
}

.cartreview-block li span {
    float: right;
}

.cartpro-img {
    width: 120px;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #ddd;
}

.order-img {
    width: 200px;
}

.cartpro-content {
    width: calc(100% - 125px);
    display: inline-block;
    padding-left: 15px;
}

.cartpro-content h4 {
    font-weight: normal;
    font-size: 18px;
    color: #111111;
    margin: 0 0 5px;
    overflow: hidden;
}

.cartpro-content .star-rate-show {
    text-align: left;
}

.cartpro-content .star-rate-show {
    text-align: left;
    margin: 0 0 5px;
}

.orders-content {
    display: flex;
    align-items: center;
}

.price-detail {
    color: rgb(255, 166, 0);
    font-size: 15px;
    display: inline;
    padding-left: 10px;
}

.order-price-detail {
    font-size: 15px;
    display: inline;
    padding-left: 10px;
}

.qtyremovebtnblk {
    display: flex;
}

.qtyremovebtnblk .plusminus-qty .input-group button {
    height: 30px;
    padding: 0px 12px;
}

.qtyremovebtnblk .plusminus-qty .input-group input {
    padding: 2px 12px;
    height: 30px;
}

.qtyremovebtnblk .btn-primary {
    padding: 2px 12px;
    height: 30px;
    line-height: 0;
    min-height: 30px;
    background: #ce0000 !important;
    border: 1px solid #ce0000 !important;
}

.qtyremovebtnblk .plusminus-qty {
    margin-right: 20px;
}

.cart-product-blk {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.cart-product-blk:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.payment-accordion .accordion-item {
    border: 1px solid #ddd;
}

.payment-accordion .accordion-item .accordion-button {
    padding: 15px 20px;
    font-weight: 600;
    color: #000;
}

.payment-accordion .accordion-item .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fff;
}

.payment-accordion .accordion-item .accordion-button img {
    margin-left: 20px;
    height: 20px;
    width: auto;
}

.custom-control.custom-radio .custom-control-label {
    margin-left: 10px;
    font-size: 15px;
}

.custom-control.custom-checkbox .custom-control-label {
    margin-left: 10px;
}

.successfull {
    text-align: center;
    box-shadow: 0px 0px 10px #ddd;
    padding: 40px;
    border-radius: 10px;
    margin: 0 0 30px;
    background: #fff;
}

.successfull img {
    width: 80px;
    margin: 0 0 20px;
}

.successfull h3 {
    font-size: 45px;
    text-transform: uppercase;
    margin: 0 0 10px;
    color: #307900;
}

.successfull p {
    font-size: 19px;
    margin: 0;
}

.ordersuccess-detail {
    border-top: 1px solid #ddd;
    margin-top: 15px;
    padding-top: 15px;
}

.ordersuccess-detail .btn-primary {
    margin-top: 20px;
}

.sidebar-dashboard {
    box-shadow: 0px 0px 8px #eaeaea;
    border-radius: 10px;
    margin: 0 0 30px;
    background: #fff;
}

.user-details-side {
    background: #000;
    padding: 1rem;
    border-radius: 0.4rem;
}

.user-img {
    width: 75px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    overflow: hidden;
}

.user-info {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 80px);
    padding-left: 20px;
}

.user-info h4 {
    font-size: 20px;
    margin: 0 0 10px;
    font-weight: 600;
    color: #fff;
}

.user-info p {
    color: #fff;
    margin: 0;
    font-size: 14px;
}

.user-info p i {
    font-size: 12px;
    padding-right: 3px;
}

.sidebar-dashboard ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-dashboard ul li a {
    font-size: 16px;
    color: #000 !important;
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #ddd;
}

.sidebar-dashboard ul li a.active,
.sidebar-dashboard ul li a:hover {
    background: #f7f9ff;
}

.sidebar-dashboard ul li i {
    width: 20px;
    text-align: center;
    margin-right: 5px;
}

.last-login {
    padding: 20px;
    background: #f7f9ff;
    font-size: 14px;
}

.dashboardHeading {
    font-size: 20px;
    margin: 0 0 20px;
}

.product-img-blk .favourite-btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
}

.address-model-block .modal-dialog {
    max-width: 950px;
}

.add-address-blk {
    /* border: 2px dotted #889ac9; */
    padding: 10px;
    font-size: 16px;
    display: block;
    font-weight: 700;
    color: #244494 !important;
}

.address-block {
    margin: 15px 0 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
}

.address-details {
    display: inline-block;
    width: calc(100% - 75px);
    padding-right: 10px;
    vertical-align: top;
}

.address-details h5 {
    margin: 0 0 10px;
}

.address-details h5 span {
    font-size: 12px;
    background: #dee6fb;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    text-transform: uppercase;
    color: #3d4863;
}

.address-details h4 {
    font-size: 16px;
    margin: 0 0 5px;
    color: #333;
}

.address-details h4 b {
    padding-right: 20px;
}

.address-details p {
    margin: 0 0 5px;
}

.address-action {
    display: inline-block;
    width: 70px;
    vertical-align: top;
}

.actionbtn {
    width: 30px;
    height: 30px;
    display: inline-block;
    background: #ddd;
    color: #fff;
    text-align: center;
    font-size: 13px;
    line-height: 28px;
    border-radius: 6px;
}

.editbtn {
    background: #ffb800;
    color: #fff !important;
    margin-right: 5px;
}

.deletebtn {
    background: #f73b02;
    color: #fff !important;
}

.setdefault-btn {
    text-transform: uppercase;
    font-size: 12px;
    background: #ffb800;
    color: #fff !important;
    padding: 5px 12px;
    display: inline-block;
    border-radius: 30px;
    margin: 10px 0 0;
    font-weight: bold;
}

.trackorder-section {
    padding: 20px 0 40px;
}

.trackorderblk {
    text-align: center;
    /* box-shadow: 0px 0px 10px #ddd; */
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.order-track-status {
    border: 1px solid black;
    padding: 20px;
}


/* ===== PRICING PAGE ===== */

.price-tabs {
    background-color: #fff;
    -webkit-box-shadow: 0 5px 20px 0 rgba(39, 39, 39, 0.1);
    box-shadow: 0 5px 20px 0 rgba(39, 39, 39, 0.1);
    display: inline-block;
    padding: 7px;
    border-radius: 40px;
    border: 1px solid #ffb800;
    margin-bottom: 45px;
}

.price-tabs .nav-link {
    color: #ffb800;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    /* font-family: 'Montserrat', sans-serif; */
    font-size: 16px;
    padding: 12px 35px;
    display: inline-block;
    text-transform: capitalize;
    border-radius: 40px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.price-tabs .nav-link.active {
    background-color: #ffb800;
    color: #fff;
}

.price-item {
    background-color: #fff;
    -webkit-box-shadow: 0 5px 30px 0 rgba(39, 39, 39, 0.15);
    box-shadow: 0 5px 30px 0 rgba(39, 39, 39, 0.15);
    border-radius: 10px;
    margin: 10px 20px 30px !important;
}

.price-item .price-top {
    -webkit-box-shadow: 0 5px 30px 0 rgba(39, 39, 39, 0.15);
    box-shadow: 0 5px 30px 0 rgba(39, 39, 39, 0.15);
    padding: 50px 0 25px;
    background-color: #ffb800;
    border-radius: 10px;
    position: relative;
    z-index: 0;
    margin-bottom: 33px;
}

.price-item .price-top:after {
    height: 50px;
    width: 100%;
    border-radius: 0 0 10px 10px;
    background-color: #ffb800;
    position: absolute;
    content: "";
    left: 0;
    bottom: -17px;
    z-index: -1;
    -webkit-transform: skewY(5deg);
    transform: skewY(5deg);
    -webkit-box-shadow: 0 5px 10px 0 rgba(113, 113, 113, 0.15);
    box-shadow: 0 5px 10px 0 rgba(113, 113, 113, 0.15);
}

.price-item .price-top * {
    color: #fff;
}

.price-item .price-top h2 {
    font-weight: 700;
}

.price-item .price-top h2 sup {
    top: 13px;
    left: -5px;
    font-size: 0.35em;
    font-weight: 500;
    vertical-align: top;
}

.price-item .price-content {
    padding: 30px;
    padding-bottom: 40px;
}

.price-item .price-content li {
    position: relative;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
}

.price-item .price-content .zmdi-check {
    color: #28a745;
}

.price-item .price-content .zmdi-close {
    color: #f00;
}

.popular {
    background-color: #ffb800;
}

.popular .price-top {
    background-color: #fff;
}

.popular .price-top:after {
    background-color: #fff;
}

.popular .price-top h4 {
    color: #101f41;
}

.popular .price-top h2,
.popular .price-top span,
.popular .price-top sup {
    color: #ffb800;
}

.popular .price-content ul *,
.popular .price-content ul .zmdi-close,
.popular .price-content ul .zmdi-check {
    color: #fff !important;
}


/* ===== PRICING PAGE ===== */

.team-block .content {
    text-align: center;
}

.gallery-block {
    margin: 15px 0;
}

.bestsellers-blk {
    padding: 0;
    margin: 0;
    list-style: none;
}

.seller-block-best img {
    width: 50px;
    height: 50%;
    object-fit: cover;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

.seller-cont-blk {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 50px);
    padding-left: 15px;
}

.seller-cont-blk h4 {
    font-size: 16px;
    color: #000;
    margin: 0 0 2px;
}

.seller-cont-blk p {
    color: #000;
    margin: 0;
}

.bestsellers-blk li {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
}

.bestsellers-blk li:last-child {
    padding: 0;
    margin: 0;
    border: none;
}


/* Blog Details Page Css */

.seller-product-section {
    cursor: pointer;
}

.blog_item {
    margin-bottom: 50px;
}

.blog_item_img {
    position: relative;
}

.blog_area a {
    color: "Sen", sans-serif !important;
    text-decoration: none;
    transition: 0.4s;
}

.blog_item_img .blog_item_date {
    position: absolute;
    bottom: -10px;
    left: 10px;
    display: block;
    color: #fff;
    background-color: #f8bb21;
    padding: 8px 15px;
    border-radius: 5px;
}

.blog_item_img .blog_item_date h3 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    line-height: 1.2;
}

.blog_item_img .blog_item_date p {
    font-size: 18px;
    margin-bottom: 0;
    color: #fff;
}

.blog_details {
    padding: 30px 0 20px 10px;
    box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
    background: #fff;
}

.blog_area a {
    color: #000 !important;
    text-decoration: none;
    transition: 0.4s;
}

.blog_details h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
}

.blog_details p {
    margin-bottom: 20px;
}

.blog-info-link li {
    display: inline-block;
    font-size: 14px;
}

.blog-info-link li a {
    color: #999999;
}

.blog-info-link li i,
.blog-info-link li span {
    font-size: 13px;
    margin-right: 5px;
}

.blog-info-link li::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px;
}

.blog-info-link li:last-child::after {
    content: none;
}

.blog_details a:hover {
    color: #2d3092;
}

.blog_area a:hover,
.blog_area a :hover {
    text-decoration: none;
    transition: 0.4s;
}

.blog-pagination {
    margin-top: 40px;
}

.blog-pagination .page-link {
    font-size: 14px;
    position: relative;
    display: block;
    padding: 0;
    text-align: center;
    margin-left: -1px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 0 !important;
    color: #8a8a8a;
    border: 1px solid #b3c9e6;
    margin-right: 10px;
}

.blog-pagination .page-link i,
.blog-pagination .page-link span {
    font-size: 13px;
}

.blog-pagination .page-item.active .page-link {
    background-color: #e6f1ff;
    border-color: #b3c9e6;
    color: #888888;
}

.blog_right_sidebar .single_sidebar_widget {
    background: #fff;
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}

.blog_right_sidebar .widget_title {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;
    text-transform: uppercase;
}

.blog_right_sidebar .widget_title::after {
    content: "";
    display: block;
    padding-top: 15px;
    border-bottom: 1px solid #b3c9e6;
}

.blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 1px solid #f0e9ff;
    transition: all 0.3s ease 0s;
    padding: 12px;
}

.blog_right_sidebar .post_category_widget .cat-list li:last-child {
    border-bottom: 0;
}

.blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 16px;
    line-height: 20px;
    color: #404040;
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0px;
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0px;
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
    justify-content: center;
    align-self: center;
    padding-left: 20px;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    transition: all 0.3s linear;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0px;
    overflow: hidden;
    height: 33px;
}

.blog_right_sidebar .popular_post_widget .post_item+.post_item {
    margin-top: 20px;
}

.blog_right_sidebar .popular_post_widget .post_item img {
    width: 80px;
    border-radius: 8px;
}

.single-post-area .blog_details {
    padding-top: 26px;
}

.single-post img {
    width: 100%;
    height: 350px;
}

.single-post-area .navigation-top {
    padding-top: 15px;
    border-top: 1px solid #f0e9ff;
}

.single-post-area .navigation-top .like-info {
    font-size: 14px;
}

.single-post-area .navigation-top p {
    margin-bottom: 0;
}

.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
    font-size: 16px;
    margin-right: 5px;
}

.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
    font-size: 14px;
    color: #999999;
}

.single-post-area .navigation-top .social-icons li {
    display: inline-block;
    margin-right: 15px;
}

.single-post-area .navigation-top .social-icons li:last-child {
    margin: 0;
}

.single-post-area .blog-author {
    padding: 20px;
    background: #e9efff;
    margin-top: 20px;
}

.single-post-area .blog-author img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 30px;
}

.single-post-area .blog-author a {
    display: inline-block;
}

.single-post-area .blog-author h4 {
    font-size: 18px;
    color: #000;
}

.single-post-area .blog-author p {
    margin-bottom: 0;
    font-size: 15px;
}

.comments-area {
    background: #fff;
    padding: 25px;
    margin-top: 30px;
}

.comments-area h4 {
    margin-bottom: 25px;
    font-size: 22px;
    color: #000;
}

.comments-area .comment-list {
    padding-bottom: 20px;
}

.comments-area .thumb {
    margin-right: 20px;
}

.comments-area .thumb img {
    width: 70px;
    border-radius: 50%;
}

.comments-area .comment {
    margin-bottom: 10px;
    color: #777777;
    font-size: 15px;
}

.comments-area h5 {
    font-size: 16px;
    margin-bottom: 0px;
}

.comments-area h5 a {
    color: #000 !important;
}

.comments-area .date {
    font-size: 14px;
    color: #999999;
    margin-bottom: 0;
    margin-left: 20px;
}

.comment-form {
    border-top: 1px solid #eee;
    padding-top: 25px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.comment-form h4 {
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 22px;
    color: #000;
}


.blog-grid-block-content p {
    margin-bottom: 0.2rem;
}


@media (max-width: 767px) {
    .welcome-text {
        text-align: center;
    }
    .float-right {
        text-align: none;
    }
    .welcome-text {
        text-align: center;
        padding: 10px 0 0;
    }
    .topmenublk ul {
        text-align: center;
    }
    .logo-searchbar-block {
        padding: 10px;
    }
    .searchheade-block {
        margin-bottom: 10px;
        padding: 2px 5px;
    }
    .bottom-header-section {
        position: relative;
    }
    .navbar-toggler {
        background: #fff !important;
        padding: 4px 7px !important;
        position: absolute;
        top: -38px;
        right: 10px;
    }
    .navbar-toggler:focus {
        box-shadow: none !important;
    }
    .mainmenu-block .nav-link.dropdown-toggle::after {
        right: 8px;
        position: absolute;
        top: 15px;
    }
    .mainmenu-block .navbar-nav .dropdown-menu {
        position: relative !important;
        border: none;
        padding: 0 15px 0;
    }
    .all-department-menu .submenu.dropdown-menu {
        position: relative !important;
        margin: 0;
        border: none;
        padding: 0 15px 0;
    }
    .top-footer {
        padding: 20px 0;
    }
    .mobile-hidden {
        display: block;
    }
    .foot-social-list {
        justify-content: left;
        margin-top: 7px;
        margin-left: -3px;
    }
    .foot-social-list li a {
        padding: 0 10px;
    }
    .foot-menu-list li a {
        padding: 3px 10px;
    }
    .category-main-blk .col {
        flex: 50%;
    }
    /* .caetfory-small-block {
        margin-bottom: 20px;
    } */
    .bfs-offer-img {
        margin-top: 40px;
    }
    .super-deal-bfs-img {
        margin-bottom: 20px;
    }
    .product-block {
        margin-bottom: 20px;
    }
    .feature-blkpro-header ul {
        display: none;
    }
    .clearence-dresswere-img {
        min-height: 300px;
    }
    .home-smartphone-section {
        margin-bottom: 20px;
    }
    .product-imgslider {
        margin-bottom: 10px;
    }
    .review-date {
        color: #555555 !important;
    }
    .product-details-cont h4 span {
        display: block;
        margin-bottom: 10px;
    }
    .qty-cart-fav-block {
        display: block;
    }
    .qty-cart-fav-block .qty-plsminus-blk {
        margin: 0 0 15px;
    }
    .qty-cart-fav-block .btn {
        margin-right: 5px;
    }
    .share-product {
        display: block;
    }
    .share-product span {
        margin: 0 0 10px;
        display: block;
    }
    .product-details-main-block {
        margin-bottom: 20px;
    }
    .productsdetails-tab .nav.nav-tabs {
        justify-content: center;
    }
    .productsdetails-tab .nav-tabs .nav-link {
        margin: 3px;
    }
    .cartpro-img {
        /* width: 100%; */
        display: block;
        margin-bottom: 20px;
    }
    .cartpro-content {
        width: 100%;
        display: block;
    }
    .orders-grid {
        margin-top: 15px;
    }
    /* .purchase {
    padding: 20px 0 0 0;
  } */
    .order-payment {
        background-color: #28a745;
    }
    .searchheade-block .input-group-text {
        display: none;
    }
    .searchheade-block .input-group-text.btn-search {
        display: block;
    }
    .input-group-text {
        display: block !important;
        text-align: center;
    }
    .custom-control.custom-checkbox {
        display: flex;
    }
    .accinfo {
        margin-top: 20px;
    }
    .price-item {
        margin: 0 0 20px !important;
    }
    .price-item .price-content {
        padding: 20px 10px;
    }
    .price-item .price-content li {
        text-align: left;
    }
    .price-item .price-content ul {
        padding: 0 0 0 10px !important;
    }
    .seller-header {
        padding-left: 5px !important;
        padding-right: 5px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
    .seller-header-topmenu {
        margin-right: 5px !important;
    }
}

@media (min-width: 768px) {
    .price-tabs {
        margin-bottom: 60px;
    }
    .price-tabs .nav-link {
        padding: 12px 40px;
    }
    .price-item {
        margin: 0 20px;
        padding-top: 20px;
    }
    .price-item .price-top {
        margin: 0 -20px;
        border-radius: 20px;
    }
    .price-item .price-top:after {
        border-radius: 0 0 20px 20px;
    }
}

@media screen and (max-width:440px) {
    .float-right {
        float: none;
    }
}

@media (min-width: 992px) {
    .price-item .price-content li {
        padding-left: 28px;
        text-align: left;
    }
    .price-item .price-content li i {
        position: absolute;
        left: 0;
        top: 3px;
    }
}

.span-link {
    cursor: pointer;
}

.on {
    color: #000;
}

.off {
    color: #ff0000;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.prodrev2 {
    padding: 15px 0;
}

.prodrev3 {
    padding: 15px 0;
}

.prodrev1 {
    padding: 8px 0;
}

.variant-block {
    border: 1px solid rgb(255, 166, 0);
    padding: 10px 0 2px 8px;
    /* width: 100%; */
    cursor: pointer;
    display: inline-block;
}


/* .product-detail-block {
    padding: 10px 0 2px 8px;
    margin: 0 0 13px 10px;
} */

.variant-name {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #888888;
}

.variant-price {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFB800;
}

.seller-product-width {
    width: 300px;
}

.seller-product-width img {
    width: 300px;
    height: 300px;
}

.product-detail-price {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #FFB800;
}

.variant-price-range {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFB800;
}

.variant-price-through {
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-decoration-line: line-through;
    color: #888888;
}

.variant-block:hover {
    background-color: #fee6b0;
}


/* .toggle-button {
  float: right
} */

.product-circle {
    height: 50px;
    width: 50px;
    border: 1px solid #0F3F6F;
    background-color: rgba(15, 63, 111, 0.23);
    border-radius: 50%;
    margin-left: 10px;
}

.sale-circle {
    height: 50px;
    width: 50px;
    border: 1px solid #FFC107;
    background-color: rgba(255, 193, 7, 0.23);
    border-radius: 50%;
    margin-left: 10px;
}

.earning-circle {
    height: 50px;
    width: 50px;
    border: 1px solid #1EE0AC;
    background-color: rgba(30, 224, 172, 0.23);
    border-radius: 50%;
    margin-left: 10px;
}

.orders-circle {
    height: 50px;
    width: 50px;
    border: 1px solid #17A2B8;
    background-color: rgba(23, 162, 184, 0.23);
    border-radius: 50%;
    margin-left: 10px;
}

.order-head-blk {
    display: flex;
    width: "60%";
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: "50px";
    margin: 22px 0 0 0;
    /* position: relative; */
    border-bottom: 1px solid #eeeeee;
}

.product-font {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #555555;
}

.RichEditor-root {
    background: #fff;
    font-family: "Georgia", serif;
    font-size: 14px;
    padding: 15px;
}

.RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: "Hoefler Text", "Georgia", serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: "Inconsolata", "Menlo", "Consolas", monospace;
    font-size: 16px;
    padding: 20px;
}

.RichEditor-controls {
    font-family: "Helvetica", sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
}

.RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.RichEditor-activeButton {
    color: #5890ff;
}

.draft-editor {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    margin: 25px;
    width: 60%;
}

.draft-editor {
    width: 92%;
    margin-left: 15px;
}

.pointer {
    cursor: pointer;
}

.googleButton {
    height: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 0px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}

.GoogleIcon {
    height: 20px;
    width: 20px;
}

.googleText {
    margin-top: 15px
}

.seller-blog-container {
    background-color: #FCFCFC;
    padding-top: 80px;
}

.faqbox {
    padding: 20px;
}

.faq-container {
    padding: 10px 0;
    /* margin-top: 20px; */
    border: none;
    border-radius: 16px;
}

.faq-container>div {
    padding: 1.5rem;
    margin: 0.9rem auto;
    box-shadow: 0px 0px 20px 0px rgba(8, 15, 52, 0.10);
}

.fax-container {
    display: flex;
    justify-content: space-between;
}

.GoogleIconCont {
    margin-top: 15px;
    margin-right: 10px;
}

.blog-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130.19%;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    text-align: left;
}

.blog-text {
    /* font-family: 'montserrat'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #555555;
    text-align: left;
}

.blog-read-more {
    /* font-family: 'montserrat'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 120%; */
    letter-spacing: 0.2px;
    color: #000;
    text-align: left;
}

.blog-sub-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    padding-left: 40px;
    width: 568px;
    color: #000000;
}

.seller-home-about {
    padding-left: 40px;
}

.seller-about-content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0E0E0E;
}

.order-head-blk-new {
    /* display: flex; */
    width: "60%";
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: "50px";
    margin: 22px 0 0 0;
    /* position: relative; */
    border-bottom: 1px solid #eeeeee;
}

.table-head-blk {
    border-bottom: 1px solid #eeeeee;
    padding: 15px;
    justify-content: space-between;
    /* display: flex; */
}

.sold-head-blk {
    text-align: center;
    justify-content: space-between;
    /* align-items: center; */
    padding: 20px;
    /* width: '50px'; */
    /* margin: 22px 0 0 0; */
}

.vendor-table {
    margin: 20px 0 0 0
}

.downprod-img {
    width: 70px;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #ddd;
}

.downprod-content {
    width: calc(100% - 80px);
    display: inline-block;
    padding-left: 15px;
}

.bulk-head-blk {
    /* border-bottom: 1px solid #eeeeee; */
    padding: 22px;
    justify-content: space-between;
    /* display: flex; */
}

.csv-head-blk {
    border-bottom: 1px solid #eeeeee;
    padding: 22px;
    justify-content: space-between;
    /* display: flex; */
}

.product-card-block {
    background: #ffffff;
    box-shadow: 0px 0px 19px rgb(0 0 0 / 15%);
    margin: 30px 0 0 0;
}

.bulk-card-block {
    background: #ffffff;
    box-shadow: 0px 0px 19px rgb(0 0 0 / 15%);
    margin: 15px 0 0 0;
}

.brand-card-block {
    background: #ffffff;
    box-shadow: 0px 0px 19px rgb(0 0 0 / 15%);
    margin: 20px 0 0 0;
}

.csv-card-block {
    background: #ffffff;
    box-shadow: 0px 0px 19px rgb(0 0 0 / 15%);
    margin: 20px 0 0 0;
}

.balance-circle {
    height: 50px;
    width: 50px;
    background-color: rgba(15, 63, 111, 1);
    border-radius: 50%;
}

.withdraw-circle {
    height: 50px;
    width: 50px;
    background-color: rgba(255, 184, 0, 1);
    border-radius: 50%;
}

.package-card-block {
    background: #ffffff;
    box-shadow: 0px 0px 19px rgb(0 0 0 / 15%);
    padding: 10px 50px 10px 50px;
}

.register-prof-section {
    display: flex;
    margin: 20px 0px;
    align-items: center;
}

.register-prof-section button {
    color: #FFF;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.14px;
}

.view-all-services-btn button {
    padding: 5px 10px;
    background-color: #000;
    border: none;
    color: white;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    margin-left: 5px;
}

.package-card-block h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #000000;
}

.package-card-block h3 del {
    font-size: 18px;
    margin-left: 15px;
    color: grey;
}

.package-details-button {
    border: none !important;
    border-radius: 4px !important;
}

.trial-time {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #0085FF;
}

.package-cards-btn button {
    background: #FFA400;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF !important;
    border-radius: 4px !important;
    padding: 10px 20px !important;
}

.package-short-desc {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #666666;
}

.package-card-block h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
}

.clients-section {
    background-color: #f5f5f5;
    padding: 20px;
}

.clients-info-section {
    /* margin: 20px; */
    width: 84%;
    margin: auto;
    margin-top: 20px;
}

.client-read-more {
    display: flex;
}

.recently-viewed-section {
    /* background-color: #F5F5F5; */
    padding: 20px;
}

.sub-category-section {
    margin: 12px 0 0 0;
}

.sub-category-menu {
    border: 1px solid #eeeeee;
    padding: 15px;
}

.fashion-menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    /* display: flex; */
}

.fashion-menu-list li a {
    display: block;
    padding: 8px 30px 0 0;
    font-size: 15px;
    color: #888888 !important;
}

.sub-category-product {
    padding: 30px 0 0 0;
}

.banner-text {
    position: absolute;
    top: 2234px;
    left: 400px
}

.electronics-banner-text {
    position: absolute;
    top: 3032px;
    left: 400px
}

.about-editor {
    width: 96%;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    margin: 25px;
}

.seller-header {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.seller-footer {
    background-color: #262420;
    padding-left: 200px;
    padding-top: 120px;
    padding-bottom: 45px;
    padding-right: 20px;
}

.seller-banner-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    color: #0E0E0E;
}

.seller-banner-content {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0E0E0E;
    width: 80%
}

.seller-rating {
    width: 27px;
    height: 17px;
    background: #258D35;
    border-radius: 2px;
}

.seller-product-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.01em;
    color: #000000;
    padding-left: 40px;
}

.mb-8 {
    margin-bottom: 80px;
}

.center-align {
    text-align: -webkit-center;
}

.seller-features-container {
    background: #F7F7F7;
    padding: 60px;
}

.seller-features-heading {
    /* font-family: 'Montserrat'; */
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.01em;
    color: #000000;
}

.seller-feature-box {
    /* width: 405px; */
    /* height: 374px; */
    padding: 20px;
    background: #FFFFFF;
    border-radius: 8px;
}

.font22 {
    font-size: 22px;
}

.feature-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}

.seller-features-subheading {
    width: 664px;
    /* font-family: 'Montserrat'; */
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.01em;
    color: #555555;
}

.seller-menu-header {
    background-color: #000;
    padding: 15px;
    padding-left: 25px;
    color: #fff;
}

.seller-footer-copyright {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    color: #ACACAC;
}

.dropbtn {
    /* background-color: #04AA6D; */
    color: black;
    /* padding: 16px; */
    font-size: 16px;
    border: 1px solid lightgray;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    text-align: left;
    right: 0px;
    padding: 10px;
}

.custom-dropdown {
    position: relative;
}

.dropdown-header-1 {
    background: rgba(217, 217, 217, 0.31) !important;
    padding: 6px;
    border-radius: 8px;
    font-weight: 600;
}

.selected-option {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
}


/* .dropdown-toggle {
    display: none;
  } */

.dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    position: absolute;
    width: 100%;
    display: none;
}

.dropdown-toggle:checked+.selected-option+.dropdown-list {
    display: block;
}

.dropdown-list li {
    padding: 10px;
    cursor: pointer;
}

.dropdown-list li:hover {
    background-color: #f0f0f0;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.seller-subscribe {
    width: 212px;
    height: 54px;
    background: #FF9933;
}

.query-cont {
    padding-left: 100px;
    color: #fff;
    padding-top: 45px;
    padding-bottom: 100px;
}

.query-form {
    background: #FFFFFF;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
    padding: 20px;
    width: 412px;
}

.query-form input {
    background: #F4F4F4;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    border: none;
    font-size: 15px;
}

.seller-feed {
    color: #fff;
}

.query-form textarea {
    background: #F4F4F4;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    border: none;
    font-size: 15px;
    height: 150px;
}

.mt-7 {
    margin-top: 70px;
}

.nopadding {
    padding: 0px;
}

.float-right {
    text-align: right;
}

.footer-links-text {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 32px !important;
    text-transform: uppercase !important;
    color: #ACACAC !important;
}

.footer-logo-text {
    /* font-family: 'Ropa Sans'; */
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 54px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.mt-6 {
    margin-top: 60px;
}

.footer-working-hours {
    /* margin-top: 60px; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #ACACAC;
}

.footer-working-days {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    /* margin-top: 15px; */
    font-weight: 600;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #C67A09;
}

.seller-menu-name {
    margin-right: 50px;
    color: #fff !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    margin-top: 20px;
}

.seller-menu-mobile {
    margin-right: 50px;
    /* color: #fff; */
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    margin-top: 20px;
}

.seller-logo {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.about-editor {
    width: 92%;
    margin-left: 15px;
}

.manufactures-section {
    padding: 20px;
}

.dropdown-section {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    /* align-items: center; */
    padding-right: 1rem;
    
}

.overf-drpsec {
    max-width: 100%;
    overflow-x: auto;
}
.overf-drpsec::-webkit-scrollbar {
    display: none;
}

.digital-services-parent .dropdown-section {
    min-width: 1310px;
}

.dropdown-section .dropdown {
    margin-right: 20px;
}

.dropdown-btn-color {
    color: #000 !important;
    background: rgba(217, 217, 217, 0.31) !important;
    border: 1px solid #EFEFEF !important;
    border-radius: 10px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}

.dropdown-btn-color:hover {
    color: #000 !important;
}

.partners-cards {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    margin: 20px 5px;
    padding: 20px 10px;
}

.partners-cards h6 img {
    margin-right: 10px;
}

.partners-cards h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;
}

.rating-section {
    display: flex;
    align-items: center;
}

.rating-section p {
    background: #258D35;
    border-radius: 2px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 6px 10px;
    margin-right: 10px;
}

.rating-stars {
    margin-right: 10px;
    position: relative;
}

.partners-cards h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #000000;
}

.seller-image-uploader label {
    display: inline-block;
    border: 2px solid grey !important;
    margin: 16px !important;
    text-align: center !important;
    border-radius: 4px;
    padding: 0px 25px;
}

.seller-image-uploader h2 {
    font-size: 20px;
    font-weight: 700;
    margin-left: 20px;
}

.review-details-section h2 {
    font-size: 20px;
    font-weight: 700;
    margin-left: 20px;
}

.review-uploader label {
    display: inline-block;
    /* border: 2px solid grey !important; */
    margin: 16px;
    text-align: center !important;
}

.review-uploader {
    display: flex;
    align-items: center;
    gap: 35px;
}

.profile-name {
    margin: 0px 10px 10px 0px !important;
}

.your-phone-number {
    padding: 20px;
    display: flex;
    gap: 70px;
}

.post-now button {
    background-color: #d3d3d3;
    padding: 12px 20px;
    margin: 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
}

.shop-details {
    display: flex;
}

.shop-details h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #000000;
}

.jio-mart-partner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shop-details h3 span {
    color: #37B34A;
}

.opened-year {
    margin-left: 30px;
}

.shop-types button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #000000;
    background: #F8F8F8;
    border: 1px solid #EFEFEF;
    border-radius: 6px;
    padding: 7px;
    margin: 5px 15px 5px 0px;
}

.partners-cards p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #fff;
    margin-top: 10px;
}

.partners-cards p img {
    margin-right: 10px;
}

.shop-button-section button {
    background: #258D35;
    border-radius: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 10px 15px;
    margin-right: 10px;
}

.chat-with-us button {
    border-radius: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #000000;
    padding: 13px 15px;
    margin-right: 10px;
    position: relative;
    right: 30px;
    width: 100%;
}

.chat-with-us button img {
    width: 14%;
}

.cart-badge-1 {
    position: relative !important;
    right: 5px;
    bottom: 7px;
    padding: 2px 3px !important;
    background-color: #ffb800 !important;
    color: #000 !important;
}

.shop-button-section-new button {
    background: #258D35;
    border-radius: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 10px 15px;
    margin-right: 10px;
    width: 80%;
}

.shop-button-section button img {
    margin-right: 10px;
}

.send-email-btn {
    border: 1px solid #0085FF !important;
    border-radius: 3px;
    color: #0085FF !important;
    background: none !important;
}

.growth-pera {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #000000;
}

.growth-pera img {
    margin-right: 10px;
}

.manufactures-froms {
    background: #FBFBFB;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
}

.manufactures-froms h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #000000;
}

.form-submit-btn {
    background: #000000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    color: #FFFFFF;
}

.manufactures-froms .form-select {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #888888;
}

.manufactures-froms .form-control {
    font-size: 14px;
    color: #888888;
}

.menu-cont {
    padding: 0px 20px;
}

.ad-verified-btn button {
    background: #f3f3f3;
    padding: 6px;
    border-radius: 8px;
    font-weight: 600;
}

.dropdown-options-list {
    padding: 0.5rem !important;
    list-style-type: none;
}

.prod-seller-info {
    background-color: #fff;
    border-radius: 0px;
}

.card-body-blk-con {
    padding-left: 0px;
    padding-right: 20px;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.card-body-blk-about {
    padding: 20px;
    padding-left: 0px;
}


/* start directory listing */

.directory-listing .right .right-top-btns button {
    color: #000;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.16px;
    padding: 0.3rem 1.5rem;
    background-color: #EEE;
    text-wrap: nowrap;
}

.directory-listing .right .right-top-btns .right-top-btns-div::-webkit-scrollbar {
    display: none;
}

.directory-listing .right .serchbar-div .shopsearch-grid-blk {
    margin-bottom: 0;
}

.active-tbtn-mf {
    background-color: #FFB800 !important;
}

.directory-listing .left>h3 {
    color: #FFF;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
    background-color: #FFB800;
    padding-left: 1rem;
    text-transform: uppercase;
}

.directory-listing .left .accordion-item .sub-cat label {
    width: 91%;
    display: flex;
    justify-content: space-between;
}


/* End directory listing */

.seller-info-container {
    border-left: 5px solid #000;
}

.seller-store-content {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #666666;
}

.seller-card-body {
    padding: 20px;
    font-size: 24px;
}

.seller-card-about {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.seller-block {
    border-top: 1px solid lightgray;
}

.seller-logo-container {
    border: 1px solid lightgray;
    padding: 10px;
    margin: 20px;
}

.seller-rating-padding {
    padding-left: 20px;
}

.mb-0 {
    margin-bottom: 0px;
}

.btn-padding {
    padding-left: 20px;
}

.drop-width {
    width: 1600px;
}

.dropHead {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000;
}

.Data-And-Form-Container {
    height: 200px;
    /* border: 1px solid #EFEFEF; */
    border-radius: 10px;
    padding: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.dropdown-section .form-check label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 2px 0px 10px 10px;
}

.seller-section {
    display: flex;
    margin-top: 15px;
    /* justify-content: space-between; */
    align-items: center;
    font-family: Rubik;
}

.seller-info {
    display: flex;
}

.current-date {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #555555 !important;
    margin-top: 0px !important;
    margin-bottom: 0rem;
}

.number-of-view {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #888888 !important;
    text-align: end !important;
}

.seller-section h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 0;
}

.seller-section h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #888888;
    margin-bottom: 0;
}

.digital-product-section p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-top: 10px;
}

.digital-product-section button {
    background: #258D35;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin: 0px 10px;
}

.price-section {
    display: flex;
    justify-content: space-between;
}

.price-section h4 {
    color: #555;
    font-family: Rubik;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.11px;
    text-transform: uppercase;
}

.price-section h3 {
    color: #000;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;
}

.paddind-1 {
    padding: 0px 10px;
}

.digital-product-section {
    margin-top: 50px;
}

.digital-product-section-border {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
}

.seller-first-section {
    margin: 60px 0px;
}

.seller-first-section h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    margin-left: 40px;
    margin-top: 50px;
    color: #0E0E0E;
}

.seller-product-section p {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 15px 0px 10px 5px !important;
}

.product-rating-btn {
    background: #258D35;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin: 0px 9px;
}

.currency-symbol-offer {
    color: #1351C1;
    font-size: 20px;
    margin-left: 0px;
}

.currency-symbol-price {
    color: gray;
    font-size: 17px;
    text-decoration: line-through;
    margin-top: 3px;
}

.currency-section {
    display: flex;
    align-items: baseline;
    gap: 13px;
}

.send-enquiry-btn button {
    border: 1px solid #FF9900;
    border-radius: 3px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #000000;
    padding: 7px;
}

.send-enquiry-btn {
    margin: 0px 15px 15px 15px;
}

.seller-product-section h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 10px 10px;
}

.seller-first-section p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 40px;
    color: #0E0E0E;
}

.banner-avon-img {
    text-align: end;
    padding: 70px 170px 10px 10px;
}

.banner-avon-img img {
    width: 33% !important;
}

.load-more-btn {
    text-align: center;
    margin-bottom: 40px;
}

.load-more-btn button {
    background: #FF9900;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding: 12px 35px;
    color: #FFFFFF;
}

.seller-third-section {
    background: #F7F7F7;
}

.seller-third-section h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.01em;
    color: #000000;
    padding-top: 3rem;
}

.seller-third-section p {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.01em;
    color: #555555;
}

.seller-second-section h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}

.seller-faq-section p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6F6C90;
    margin-left: 20px;
}

.seller-faq-section {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6F6C90;
}

.seller-faq-section h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    color: #000000;
}

.seller-faq-section h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #000000;
}

.seller-faq-section .accordion .accordion-item .title {
    font-size: 22px !important;
    background: #FFFFFF !important;
}

.faq-left {
    padding: 30px 25px 30px 0px;
}

.faq-right {
    padding: 30px 0px 30px 25px;
}

.query-right-col {
    background: url(../public/assets/images/query-img1.png);
    opacity: 0.3;
}

.query-bg {
    background: linear-gradient(90deg, #000000 44.31%, rgba(0, 0, 0, 0) 123.75%);
    position: relative;
    bottom: 340px;
}

.query-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.query-section h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 40px;
}

.seller-footer-bg {
    background-color: #262420;
    padding: 100px;
}

.seller-footer-bg h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 54px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.seller-footer-bg p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #ACACAC;
}

.seller-footer-bg p span {
    margin-left: 15px;
}

.seller-footer-links {
    display: flex;
    justify-content: space-between;
}

.seller-location {
    display: flex;
    justify-content: space-between;
}

.seller-footer-bg .form-control {
    min-height: 60px;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border: 1px solid #FF9933;
}

.input-group span {
    background: #1351C1;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.copy-right-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    color: #ACACAC;
    opacity: 0.5;
    margin-top: 30px;
}

.seller-subscribe-card {
    background: #FFFFFF;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
    padding: 20px;
}

.get-in-touch-btn {
    background: #FF9933;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    width: 100%;
    text-align: center !important;
    align-items: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.seller-pd-2 {
    padding: 0rem 5rem 0rem 3rem;
}

.form-mt-1 {
    margin-top: 10px;
}

.form-mt-2 {
    margin-top: 20px;
}

.form-mt-3 {
    margin-top: 30px;
}

.seller-forms .form-control {
    border-radius: 6px !important;
}

.seller-forms h4 {
    font-size: 20px;
    font-weight: 700;
}

.seller-forms h5 {
    font-size: 20px;
    font-weight: 700;
    padding: 15px 20px 0px 20px;
}

.form-pd-left {
    padding-left: 20px;
}

.form-heading {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

.fuel-section button {
    padding: 8px 16px;
    border: 1px solid #d1d1d1;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
}

.seller-form-pd {
    padding: 0px 20px;
}

.seller-forms {
    width: 650px;
    margin: 40px auto;
    border: 1px solid grey;
    border-radius: 8px;
}

.price-input-group .input-group span {
    background-color: #ffffff !important;
    color: #000 !important;
}

.price-input-group {
    padding: 15px 20px 10px 20px;
}

.seller-image-uploader {
    padding: 20px 0px;
}

.location-section {
    padding: 15px 20px;
}

.location-btn {
    width: 35%;
    border-bottom: 1px solid #f3f3f3;
    font-size: 18px;
}

.list-btn {
    width: 35%;
    border-bottom: 1px solid #f3f3f3;
    font-size: 18px;
}

.location-section h2 {
    font-size: 20px;
    font-weight: 700;
}

.seller-details-header ul {
    list-style-type: none;
    display: flex;
    margin-bottom: 0rem !important;
    padding-left: 0rem;
}

.seller-details-header {
    background: #000;
    padding: 20px;
}

.seller-details-header ul li {
    border-right: 1px solid #fff;
    padding: 0px 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    cursor: pointer;
}

.seller-mr-1 {
    margin: 0px 20px;
}

.search-product-name {
    font-size: 14px;
    color: #111111;
    line-height: 18px;
    font-weight: 500;
}

.search-product-cate {
    font-size: 12px;
    color: #777777;
    line-height: 15px;
}

.seller-details-header img {
    margin-right: 5px;
}

.price-input-group h2 {
    font-size: 20px;
    font-weight: 700;
}

.price-input-group .form-control {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important
}

.call-us-section p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    text-align: right;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: #000000;
}

.call-us {
    border-right: 2px solid #000000;
    padding-right: 20px;
}

.email-us {
    padding-left: 20px;
}

.seller-fourth-section {
    display: grid;
    grid-template-columns: 40% 60%;
    column-gap: 80px;
    background: linear-gradient(90deg, #000000 44.31%, rgba(0, 0, 0, 0) 123.75%);
    margin-top: 30px;
}

.seller-mr-1 {
    background: linear-gradient(90deg, #000000 44.31%, rgba(0, 0, 0, 0) 123.75%);
}

.testimonials-form {
    width: 400px;
    margin: auto;
}

.subscription-bg-color {
    background-color: #f3f3f3;
    padding: 40px;
}

.subscription-plan {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.subscription-plan button {
    background: #f3f3f3;
    color: blue;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    padding: 4px 8px;
    margin-left: 12px;
}

.subscription-plan p {
    font-size: 18px;
}

.mr-right-1 {
    margin-right: 40px;
}

.upgrade-plan-btn {
    background: blue;
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    margin-right: 15px;
}

.renew-plan-btn {
    background: #f3f3f3;
    color: #000;
    padding: 5px 10px;
    border-radius: 8px;
    margin-right: 15px;
}

.subscription-details td img {
    margin-right: 10px;
}

.subscription-details th img {
    margin-left: 5px;
}

.subscription-details table {
    border: 1px solid #f3f3f3;
    border-radius: 4px;
}

.subscription-details .table>thead {
    background-color: #f3f3f3;
}

.mt-top-1 {
    margin-top: 30px;
}

.cancel-subscription {
    background-color: #f3f3f3;
    padding: 30px 0px;
}

.cancel-subscription-card {
    border: 1px solid #000;
    border-radius: 8px;
    padding: 25px;
}

.cancel-form-subs .form-floating {
    margin-top: 25px !important;
}

.cancel-form-subs .form-floating .form-control {
    border-radius: 6px !important;
    margin-bottom: -5px;
}

.cancel-subscription-card p {
    margin-bottom: 0.2rem !important;
    margin-top: 10px;
}

.cancel-subscription-section a {
    color: #0085FF !important;
    font-weight: 600;
}

.cancel-subscription-section p {
    margin-bottom: 0.2rem;
}

.cancel-subscription-section {
    width: 600px;
    margin: auto;
}

.cancel-plan-btn {
    background: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    margin-right: 15px;
}

.subscription-plan p img {
    width: 40% !important;
}

.subscription-plan-card {
    border: 1px solid #d9d9d9;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
}

.subscription-plan-card p {
    margin-bottom: 0rem;
}

.subscription-content-bg {
    background-color: #fff;
    padding: 25px;
}


/* .seller-pd-3 {
    background-image: url(../public/assets/images/banner-seller.png);
    opacity: 0.4;
    padding: 135px 90px !important;
    background-size: cover;
    background-repeat: no-repeat;
} */

.testimonials-bg-img2 {
    background-image: url(../public/assets/images/banner-seller.png);
    padding: 135px 90px !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.seller-testimonials-content h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #000;
}

.seller-testimonials-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.3px;
    color: #000;
}

.testimonials-head-1 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 35px 77px 30px 0px;
}

.seller-pd-3 {
    padding: 0px 50px;
}

.testimonils-section {
    display: flex;
    align-items: center;
}

.testimonils-section img {
    border-radius: 50%;
}

.seller-testimonials {
    display: flex;
    justify-content: space-between;
}

.call-us-section {
    display: flex;
    justify-content: center;
}

.seller-subscribe-card input {
    border-radius: 4px !important;
}

.seller-footer-links p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #ACACAC;
    cursor: pointer;
}

.seller-mt-1 {
    margin-top: 50px;
}

.seller-location p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-transform: uppercase;
    color: #ACACAC;
}

.seller-footer-bg p img {
    margin-left: 8px;
}

.seller-footer-logo {
    display: flex;
    justify-content: space-between;
}


/* .seller-footer-logo .form-control {
    width: 60% !important;
} */

.seller-blog-section h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    color: #000000;
}

.customer-section img {
    position: relative;
    z-index: 1;
    height: 850px;
}

.banner-card-img img {
    height: 230px;
}

.seller-blog-section p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 3rem;
}

.blog-info {
    padding: 15px;
}

.blog-info h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130.19%;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #000000;
}

.blog-info h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #555555;
}

.blog-info button {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #111111;
    padding: 0px;
}

.panel-title>a:before {
    float: right !important;
    font-family: FontAwesome;
    content: "\f068";
    padding-right: 5px;
}

.panel-title>a.collapsed:before {
    float: right !important;
    content: "\f067";
}

.panel-title>a:hover,
.panel-title>a:active,
.panel-title>a:focus {
    text-decoration: none;
}

.blog-info button img {
    margin-left: 10px;
}

.seller-product-section {
    margin-bottom: 40px;
}

.price-section h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #000000;
}

.price-section h3 del {
    color: #888888;
    margin-left: 20px;
}

.seller-feature-section {
    padding-bottom: 50px;
}

.seller-product-section-pd {
    padding: 15px;
}

.seller-third-section h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #312F3D;
    margin-top: 15px;
}

.manufacture-dropdown {
    position: absolute;
    background: #fff;
    border: 1px solid gray;
    border-radius: 4px;
    width: 300px;
    height: 200px;
    overflow: auto;
    margin-top: 3px;
}

.manufacture-dropdown-2 {
    position: absolute;
    background: #fff;
    border: 1px solid gray;
    border-radius: 4px;
    width: 150px;
    margin-top: 3px;
}

.manufacture-dropdown-3 {
    position: absolute;
    background: #fff;
    border: 1px solid gray;
    border-radius: 4px;
    width: 100px;
    margin-top: 3px;
}

.seller-third-section h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #53515E;
    overflow: hidden;
    height: 50px;
}

.seller-deatils-banner h1 {
    padding-top: 100px;
}

.seller-third-section button {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #2B2B2B;
}

.seller-third-section button img {
    margin-left: 10px;
}

.seller-about-section h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    color: #0E0E0E;
}

.seller-about-section p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #0E0E0E;
}

.read-more-btn {
    background: #FF9900;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px 15px;
    position: relative;
    top: 25px;
    cursor: pointer;
}

.seller-about-pd-right {
    padding-right: 90px;
}

.seller-about-section {
    margin: 60px 0px;
}

.digital-product-section label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #D2D2D2;
}

.seller-section img {
    margin-right: 20px;
}

.dropdown-section .form-check .form-check-input {
    width: 3em !important;
    height: 1.2rem !important;
}

.dropdown-section .form-check {
    margin: 6px 15px 10px 10px;
}

.digital-category {
    margin-bottom: 50px;
}

.digital-category h1 {
    color: #000;
    font-family: Rubik;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.22px;
    margin-bottom: 0;
}

.digital-category .register-prof-section a {
    color: #1351C1;
    font-family: Rubik;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.22px;
}


/* serviceCategory  */

.servicecat-comp .cards .card {
    width: 13%;
    border: none;
}

.servicecat-comp .cards .cat-logo {
    width: 150px;
    height: 140px;
    border-radius: 9px;
    background-color: #ECF1FB;
}

.servicecat-comp .cards .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.servicecat-comp .cards .cat-logo>img {
    width: 65px;
    height: 55px;
}

.servicecat-comp .cards .card h5 {
    color: #555;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-bottom: 1rem;
}

.Form-Content-Container {
    text-align: center;
}

.Form-Content-Container img {
    display: initial !important;
    width: 80px;
    height: 80px;
}

.Form-Content-Container p {
    margin-top: 10px;
}

.professional-bg-img {
    background: url(../public/assets/images/prof-bg-1.png);
    padding: 85px 200px 85px 50px;
    background-repeat: no-repeat;
    margin: 30px 0px;
    border-radius: 10px;
}

.professional-bg-img1 {
    background: url(../public/assets/images/prof-bg-2.png);
    padding: 85px 200px 85px 50px;
    background-repeat: no-repeat;
    margin: 30px 0px;
    border-radius: 10px;
}

.professional-section p {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.cross-btn {
    display: flex;
    justify-content: space-between;
}

.cross-btn img {
    cursor: pointer;
}

.professional-bg-img1 button {
    background: #FFB800;
    border-radius: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    padding: 10px 20px;
}

.professional-bg-img button {
    background: #FFFFFF;
    border-radius: 50px;
    padding: 5px 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
}

.professional-bg-img button img {
    background: #FFB800;
    padding: 8px;
    border-radius: 11px;
    margin-left: 20px;
}

.other-prod-container {
    display: flex;
}

.other-prod-name {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.other-prod-price {
    display: flex;
}

.other-prod-mrp {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFB800;
}

.other-prod-discount {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
    color: gray;
    text-decoration: line-through;
}

@media screen and (max-width:1300px) {
    .qty-cart-fav-block {
        display: block;
    }
    .user-info {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 80px);
        padding-left: 5px;
    }
    .user-info p {
        color: #fff;
        margin: 0;
        font-size: 12px;
    }
    .seller-product-width {
        width: 100%;
    }
    .seller-product-width img {
        width: 100%;
        height: 100%;
    }
    .seller-features-subheading {
        width: 100%;
        /* font-family: 'Montserrat'; */
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.01em;
        color: #555555;
    }
    .blog-sub-heading {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        padding-left: 40px;
        width: 100%;
        color: #000000;
    }
    .seller-footer {
        background-color: #262420;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width:1100px) and (min-width:720px) {
    .seller-card-body {
        padding: 10px;
        font-size: 18px;
    }
    .seller-rating {
        padding-left: 0px;
    }
}

@media screen and (min-width:1550px) and (max-width:1600px) {
    .plr85 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .drop-width {
        width: 1600px;
    }
}

@media screen and (min-width:1600px) and (max-width:1731px) {
    .plr85 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .shop-banner {
        background: #fff;
        padding: 20px;
        display: flex;
        max-height: 500px;
        width: 1650px;
        overflow: auto;
        overflow-x: hidden;
    }
    .drop-width {
        width: 1800px;
    }
}

@media screen and (min-width:1731px) and (max-width:1900px) {
    .plr85 {
        padding-left: 100px;
        padding-right: 100px;
    }
    .shop-banner {
        background: #fff;
        padding: 20px;
        display: flex;
        max-height: 500px;
        width: 1800px;
        overflow: auto;
        overflow-x: hidden;
    }
    .drop-width {
        width: 1900px;
    }
}

@media screen and (min-width:1900px) and (max-width:2200px) {
    .plr85 {
        padding-left: 100px;
        padding-right: 100px;
    }
    .shop-banner {
        background: #fff;
        padding: 20px;
        display: flex;
        max-height: 500px;
        width: 1800px;
        overflow: auto;
        overflow-x: hidden;
    }
    .drop-width {
        width: 2200px;
    }
}

@media screen and (min-width:2200px) and (max-width:3000px) {
    .plr85 {
        padding-left: 100px;
        padding-right: 100px;
    }
    .shop-banner {
        background: #fff;
        padding: 20px;
        display: flex;
        max-height: 500px;
        width: 1800px;
        overflow: auto;
        overflow-x: hidden;
    }
    .drop-width {
        width: 3000px;
    }
}

@media screen and (min-width:880px) and (max-width:1300px) {
    .plr85 {
        padding-left: 100px;
        padding-right: 100px;
    }
    .shop-banner {
        background: #fff;
        padding: 20px;
        display: flex;
        max-height: 500px;
        width: 1800px;
        overflow: auto;
        overflow-x: hidden;
    }
    .drop-width {
        width: 1200px;
    }
}

@media screen and (max-width:1131px) {
    .seller-header-desktop {
        display: none !important;
    }
    .seller-header-mobile {
        display: block !important;
    }
}


/* Search bar dropdown CSS */

.search {
    margin: 0 auto;
    z-index: 10000;
    /* width: 280px; */
    position: relative;
}

.search,
.search * {
    z-index: 10;
}

.search .search-btn {
    cursor: pointer;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #777;
    font-weight: 500;
}

.search-content {
    position: absolute;
    top: 40px;
    left: 0;
    width: 244px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
    background-color: #fff;
}

.search-content .item {
    padding: 10px;
    cursor: pointer;
}

.search-content .item:hover {
    background: #fcfcfc;
}

.use-current-location {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    text-align: left;
    color: blue;
    border-bottom: 1px solid lightgray;
}

.product-detail-seller-name {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    display: flex;
}

.product-seller-container {
    padding: 20px;
}

.product-detail-company-details {
    display: flex;
    justify-content: space-between;
}

.product-detail-company-details h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px;
    color: #000000;
}

.product-detail-company-details p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px;
    color: #555555
}

.product-detail-company-details-call {
    background: #258D35;
    border-radius: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 8px 20px;
}

.product-detail-company-details-modal {
    display: flex;
    justify-content: space-between;
}

.product-detail-company-details-modal h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px;
    color: #000000;
}

.product-detail-company-details-modal p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px;
    color: #555555
}

.product-detail-company-details-modal p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #555555;
}

.product-detail-form-modal label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #888888;
}

.product-detail-form-modal input {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    color: #888888;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    max-width: 385px;
    width: 100%;
    padding-left: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.product-detail-form-modal select {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    color: #888888;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    max-width: 385px;
    width: 100%;
    padding-left: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.product-detail-form-modal {
    padding-left: 20px;
}

.product-detail-form-modal button {
    background: #FF9933;
    /* width: 212px; */
    padding: 19px 29px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: uppercase;
}

.business-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.01em;
    color: #060606;
}

.business-heading-thankyou {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
}

.single-product-styling {
    display: flex;
    align-self: center;
    align-items: center;
}

.single-product-styling h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #FFB800;
    letter-spacing: -2px;
}

.single-product-styling p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: line-through;
    color: #888888;
    margin-left: 10px;
    letter-spacing: -1px;
}

.single-product-styling h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FE2424;
    margin-left: 10px;
}

.single-product-styling h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #4C9700;
    margin-left: 15px;
    letter-spacing: -1px;
}

.multiple-price-styling {
    display: flex;
    gap: 30px;
    align-items: center;
    align-self: center;
}

.multiple-price-styling h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFB800;
}

.multiple-price-styling h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000;
}

.multiple-price-styling h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #888888
}

.multiple-price-styling p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: line-through;
    color: #888888;
    margin-left: 10px;
    margin-top: 4px;
}

.product-button-and-name {
    display: flex;
    justify-content: space-between;
}

.product-detail-pieces {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
    color: #333333;
}

.thanq-req {
    /* font-family: 'Rubik'; */
    font-style: normal;
    text-align: center;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}

.thanq-req span {
    /* font-family: 'Rubik'; */
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}

.thanq-req-saperator {
    border-top: 1px solid lightgray;
}

.verified-buyer {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #555555;
    margin-top: 20px;
}

.verified-buyer span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #555555;
}

.verified-seller {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #555555;
}

.verified-seller span {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #555555;
}

.active-class {
    color: #ffb800 !important;
}

.add-new-services {
    background: #f3f3f3;
}

.add-new-services h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 10px 10px 0px 15px;
}

.add-new-form-pd {
    padding: 10px 10px 15px 15px;
}

.services-form-inputs {
    display: flex;
    margin-bottom: 15px;
    align-items: baseline;
    justify-content: space-between;
}

.services-form-inputs input {
    width: 80%;
    border-radius: 4px !important;
    font-size: 12px;
}

.services-form-inputs textarea {
    width: 80%;
    border-radius: 4px !important;
    font-size: 12px;
}

.services-form-inputs select {
    width: 80%;
    border-radius: 4px !important;
    font-size: 12px;
}

.featured-input {
    background: none;
    height: 30px;
    border: 1px solid lightgray;
}

.service-detail-page-detail {
    padding-left: 40px;
    padding-right: 40px;
    font-family: Rubik;
}

.service-detail-page-detail h6 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.2px;
    font-family: Rubik
}

.service-detail-page .top-skills .skills {
    gap: 0.8rem;
    flex-wrap: wrap;
}

.service-detail-page .top-skills .skills span {
    color: #555;
    font-family: Rubik;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.13px;
    background: red;
    padding: 0.4rem 1.2rem;
    border: 1px solid #EBEBEB;
    background: #EBEBEB;
    border-radius: 26px;
    text-wrap: nowrap;
    transition: 0.4s ease;
}

.service-detail-page .top-skills .skills span:hover {
    background-color: #258d35e6;
    border: 1px solid #258d35e6;
    color: #fff;
}

.service-detail-page-detail p {
    font-style: normal;
    font-family: Rubik;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0px;
    letter-spacing: 0.01em;
    margin-top: 10px;
    color: #111111
}

.service-detail-author {
    display: flex;
    margin-top: 20px;
}

.service-detail-author h6 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
    font-family: Rubik;
}

.service-detail-author p {
    font-family: Rubik;
    font-weight: 300;
    font-size: 14px;
    margin-left: 10px;
    line-height: 22px;
    margin-left: 0;
    margin-bottom: 0px;
    letter-spacing: 0.01em;
    margin-top: 0px;
    color: #111111
}

.package-box {
    border: 1px solid lightgray;
    max-width: 400px;
    width: 100%;
}

.package-box h2 {
    color: #000;
    font-family: Rubik;
    font-size: 22px;
    font-weight: 500;
}

.section-media-900 {
    display: none;
}

.package-box h3 {
    color: #000;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
}

.package-box h3 span {
    font-weight: 600;
    color: #000;
}

.package-box h4 {
    color: #555;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
}

.package-box h4 span {
    font-weight: 500;
}

.package-box p {
    color: #555;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
    margin-bottom: 0;
}

.package-box-packages {
    border: 1px solid lightgray;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.01em;
    background: #F8F8F8;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: #BABABA;
    background: #F8F8F8;
    padding: 20px;
    text-align: center;
}

.package-box-packages {
    color: #777;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;
    position: relative;
}

.package-box-packages:hover {
    cursor: pointer;
}

.pboxes:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #FF9900;
    bottom: 0;
    left: 0;
}

.package-activebtn {
    color: #F90;
}

.service-detail-page .right .what-include {
    color: #000;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
}

.service-detail-page .right .nword-inp {
    padding: 0.4rem;
    width: 35%;
    border: 1px solid #ACACAC;
}

.rc-auther .rating-digit,
.more-services .sp-rating .rating-digit {
    padding: 0.05rem 0.5rem;
    display: flex;
    align-items: center;
    border-radius: 0.4rem;
    background-color: #258D35;
    color: #FFF;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 500;
}

.rc-auther p,
.more-services .sp-rating .rating-people {
    color: #D2D2D2;
    font-size: 14px;
    font-weight: 400;
}

.rc-auther .countary p {
    color: #888;
    padding-left: 1rem;
}

.package-box-list {
    color: #777;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    padding-left: 0.7rem
}

.package-box-list-button {
    background-color: #000;
    color: #FFF;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.14px;
    border-radius: 0.4rem;
    width: 100%;
    padding: 1rem;
}

.service-detail-page-detail .carousel-slider .control-prev,
.service-detail-page-detail .carousel-slider .control-next {
    background-color: #fff;
    opacity: 1;
    display: block;
    height: fit-content;
    margin: auto;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.service-detail-page-detail .carousel-slider .control-prev {
    margin-left: 0.6rem;
}

.service-detail-page-detail .carousel-slider .control-prev::before,
.service-detail-page-detail .carousel-slider .control-next::before {
    border: 0 !important;
    color: #777777;
    position: absolute;
    top: -0.27rem;
}

.service-detail-page-detail .carousel-slider .control-prev::before {
    content: "<";
    left: 0.2rem;
}

.service-detail-page-detail .carousel-slider .control-next::before {
    content: ">";
    right: 0.2rem;
}

.service-detail-page-detail .peolpe-love .carousel-slider .control-prev {
    left: -1rem;
}

.service-detail-page-detail .peolpe-love .carousel-slider .control-next {
    right: -0.5rem;
}

.service-detail-page-detail table tbody tr.bg-lgray td {
    background: #EEE;
}

.service-detail-page-detail table tbody tbody tr:hover td {
    background: #f7f7f7;
}

.sub-category-product a {
    color: #000;
}

.user-dropdown {
    border-radius: 10px;
    padding: 0px 15px 3px 15px;
    border: 1px solid #ffb800;
}

.call-btn-service {
    background: #258D35 !important;
    padding: 10px 0px;
    width: 90%;
    color: #fff;
    display: flex;
    gap: 0.6rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
}

.chat-btn-service {
    background: #0085FF !important;
    padding: 10px 0px;
    width: 90%;
    color: #fff;
    display: flex;
    gap: 0.6rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
}

.service-detail-page-detail .about-service p {
    color: #555;
}

.service-detail-page-detail .compare-package table th>div {
    gap: 0.4rem;
}

.service-detail-page-detail .compare-package table tbody>tr td:first-child {
    text-wrap: nowrap;
    text-align: start;
}

.service-detail-page-detail .compare-package table tbody>tr td.check {
    color: #2CC243;
}

.service-detail-page-detail .compare-package table tbody>tr td.digit {}

.service-detail-page-detail .compare-package table tbody>tr td:not(:first-child) {
    text-align: center;
    vertical-align: middle;
}

.service-detail-page-detail .compare-package table th {
    vertical-align: top;
}

.service-detail-page-detail .compare-package table th span {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.service-detail-page-detail .compare-package table th span.heading {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.service-detail-page-detail .compare-package table th p {
    color: #000;
    color: #555;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.1px;
    margin-top: 0;
}

.service-detail-page-detail .compare-package table td.digit {
    color: #000;
    font-size: 15px;
}

.service-detail-page-detail .compare-package table tr.dtime {
    color: #888;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
}

.service-detail-page-detail .compare-package table tr.dtime p {
    color: #888;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
}

.service-detail-page-detail .compare-package table tr.dtime span {
    height: 14px;
    width: 14px;
    display: inline-block;
    background: #4CB919;
    border: 2px solid #4CB919;
    border-radius: 50%;
}

.service-detail-page-detail .compare-package table tr.nwords p {
    border: 1px solid #ACACAC;
    width: fit-content;
    padding: 0.4rem 2rem;
    margin: auto;
    align-items: center;
    color: #3D3D3D;
}

.service-detail-page-detail .compare-package table tr.continue-btns p {
    color: #888;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.15px;
}

.service-detail-page-detail .compare-package table tr.continue-btns button {
    padding: 0.6rem;
    width: 70%;
    margin: 0.6rem auto;
    color: #FFF;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 700;
    background-color: #000000;
    border-radius: 0.4rem;
}

.service-detail-page-detail .faq-container dt img {
    position: absolute;
    width: 43px;
    height: 43px;
    right: 1rem;
    top: 0;
}

.service-detail-page-detail .faq-container dt {
    color: #1F1F1F;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}


/* 
/////////////////////////////////////////////////
/////////////////////////////////////////////////

*/

.service-detail-page .more-services {
    padding-left: 40px;
    padding-right: 40px;
}

.service-detail-page .more-services .scards .card {
    border: 1px solid #EFEFEF;
    border-radius: 0.7rem;
}

.service-detail-page .more-services .scards .card .card-body {}

.service-detail-page .more-services .scards .card h5 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}

.service-detail-page .more-services .scards .sprovider p {
    color: #888;
    font-size: 12px;
    font-weight: 400;
    margin-top: 0;
}

.service-detail-page .more-services .scards .card .hpara {
    color: #000;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    margin: 0.8rem auto;
}

.service-detail-page .more-services .scards .card .price-cont,
.service-detail-page .more-services .scards .card .member-since {
    margin-top: 1rem;
    border-top: 1px solid #EFEFEF;
    padding-top: 0.5rem;
}

.service-detail-page .more-services .scards .card .price-cont span {
    color: #555;
    font-family: Rubik;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.11px;
    text-transform: uppercase;
}

.service-detail-page .more-services .scards .card .price-cont .heart i {
    color: #C1C1C1;
    font-size: 35px;
}

.service-detail-page .more-services .scards .card .price-cont p {
    color: #000;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;
    margin-bottom: 0;
}

.service-detail-page .more-services .scards .card .price-cont p.dprice {
    color: #888;
    text-decoration: line-through;
}

.service-detail-page .more-services .scards .card .member-since p {
    color: #555;
    font-family: Rubik;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.11px;
    margin-bottom: 0;
    margin-top: 0.4rem;
}

.service-detail-page .more-services .scards .card .card-body {
    padding-left: 0;
    padding-right: 0;
}

.service-detail-page .more-services>h6 {
    color: #000;
    font-family: Rubik;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.2px;
}

.inquiry-modal-pedding {
    padding: 40px;
}

.header-items {
    display: flex;
    justify-content: space-between;
}

.pop-cities {
    border-left: 3px solid lightgray;
    display: flex;
    margin-left: 10px;
}

.pop-city {
    margin-left: 6px;
    margin-right: 6px;
}

.footer-logo {
    padding: 15px 0 0 30px;
}

.owl-prev {
    height: 24px;
}

.bottom-header-768 {
    display: none;
}

.owl-next {
    height: 24px;
}

.owl-next span {
    position: relative !important;
    bottom: 13px !important;
    font-size: 32px !important;
}

.owl-prev span {
    position: relative !important;
    bottom: 13px !important;
    font-size: 32px !important;
}

.footer-contact-list p {
    color: #fff;
}

.footer-para {
    color: #fff;
    padding: 25px 0 0 30px;
}

.footer-pd-1 {
    padding: 0 0 0 55px;
}

.section-768 {
    display: none;
}


/* .hide-in-desktop {
    display: none;
} */

.payment-section {
    color: #fff;
}

.top-header-dropdown {
    display: flex;
}

.header-on-landscape {
    display: none;
}

.dashboard-btn {
    float: right;
    margin-top: -20px;
}

.seller-dashboard-btn {
    float: right;
    margin-top: -20px;
}

.tax-switch {
    margin-top: 0px;
    font-size: 18px;
    float: right;
}

.media-1024 {
    display: none;
}

.slider-banner-vendor {
    display: flex;
    justify-content: space-between;
}

.vendor-slider-section {
    margin-left: 20%;
}

.vendor-heading {
    display: flex;
}

.slider-heading {
    display: flex;
    justify-content: space-between;
}

.add-product-checkboxes {
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.vendor-enquiry-page {
    display: flex;
    width: "60%";
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: "50px";
    margin: 22px 0 0 0;
    border-bottom: 1px solid #eeeeee;
}

.about-us-slider .carousel.carousel-slider {
    overflow: visible !important;
}

.about-us-slider .carousel .control-dots {
    bottom: -30px;
}

.ptb-100 {
    padding: 100px;
}

.verify-success-text {
    font-size: 20px
}

.verify-link-text {
    color: #0085FF;
    text-decoration: underline;
}

.switch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}



@media only screen and (max-width: 800px) {
    /* Style for the switch label */
    .switch-label {
        margin-bottom: 10px;
    }
    /* .dropdown-section {
        display: grid;
        grid-template-columns: 30% 30% 30%;
    } */
    .banner-card-img img {
        width: 100%;
        margin-top: 15px;
    }
    .slider-section .owl-nav {
        top: -3.3rem !important;
    }
    /* Style for the switch slider */
    .switch-slider {
        width: 40px;
        height: 80px;
        background-color: #ccc;
        border-radius: 20px;
        position: relative;
        cursor: pointer;
    }
    .form-check-input {
        /* width: 47px !important; */
    }
    /* Style for the switch thumb */
    .switch-thumb {
        width: 38px;
        height: 38px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 1px;
        transition: transform 0.4s;
    }
    /* Style for the switch thumb when it's checked (on) */
    .switch-slider.checked .switch-thumb {
        transform: translateY(40px);
    }
    .vendor-checkout-section {
        display: flex;
        justify-content: right;
    }
    .wish-card-width {
        margin-bottom: 15px;
    }
    .whatsapp-icon-color {
        color: #000;
    }
    .work-hour-width {
        width: 100px;
    }
    .manufactures-textarea {
        color: #888888;
        font-weight: 400;
        font-size: 14px;
    }
    .accprofile {
        text-align: center;
    }
    .vendor-order-mobile {
        display: block;
    }
    .package-card-block h3 {
        font-size: 18px;
    }
    /* .varient-inputs {
    width: 200px;
    padding: 2px 10px 15px 10px;
    gap: 10px;
} */
    /* .varient-inputs .form-control {
    min-height: 30px !important;
    font-size: 12px;
} */
    .mobile-pd-bottem {
        padding-bottom: 20px;
    }
    .addproducts-head .form-label {
        margin-bottom: 0rem !important;
    }
    /* .mb-2 {
        margin-bottom: 0rem !important;
    } */
    .slider-heading h4 {
        font-size: 16px;
    }
    .add-new-slider-btn {
        font-size: 14px;
        min-height: 30px;
    }
    .work-hour-width {
        width: 85px;
        font-size: 12px;
    }
    .mt-5 {
        margin-top: 1rem !important;
    }
    .mobile-working-hours {
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
    }
    .mobile-pd {
        padding: 0px 20px;
    }
    .vendor-checkout-section h4 {
        font-size: 16px;
    }
    .vendor-checkout-section {
        justify-content: left;
    }
    .vendor-enquiry-page {
        display: block;
    }
    .add-product-checkboxes {
        grid-template-columns: 100%;
    }
    .mobile-margin-2 {
        margin-bottom: 20px;
    }
    .vendor-product {
        display: block;
    }
    /* .slider-heading {
        display: block;
    } */
    .dashboard-btn {
        float: right;
        margin-left: 5px;
        font-size: 14px;
        min-height: 30px;
        margin-top: -25px;
    }
    .seller-dashboard-btn {
        float: right;
        margin-top: -5px;
    }
    .vendor-heading h5 {
        font-size: 18px;
    }
    .tax-switch {
        margin-top: 0px;
        font-size: 18px;
        float: initial;
    }
    .mt-5 {
        margin-top: 1rem;
    }
    .mt-8 {
        margin-top: 20px;
    }
    .pd-zero {
        padding: 0px !important;
    }
    .vendor-slider-section {
        margin-left: 0%;
    }
    .vendor-heading {
        display: flex;
        margin-top: 15px;
    }
    .header-section {
        position: relative;
    }
    .mobile-screen-button {
        font-size: 12px;
        padding: 5px;
        min-height: 30px;
    }
    .downprod-content p {
        margin-bottom: 0rem;
    }
    .mobile-margin-1 {
        margin-top: 20px;
    }
    .subscription-bg-color {
        padding: 20px 8px;
    }
    .mobile-margin-3 {
        margin-top: 10px;
    }
    .mobile-margin-4 {
        margin-bottom: 10px;
    }
    .package-details-button {
        padding: 5px 10px !important;
        font-size: 12px;
    }
    .varient-inputs {
        width: 200px;
        padding: 2px 10px 15px 10px;
        gap: 10px;
    }
    .varient-inputs .form-control {
        min-height: 30px !important;
        font-size: 12px;
    }
    .varient-head-3 {
        position: relative;
        top: 55px;
    }
    .package-table-cell {
        position: sticky;
        left: 0;
        background-color: #fff !important;
        z-index: 1;
        width: 100px !important;
        font-size: 12px;
    }
    .varient-mobile {
        display: flex;
        align-items: baseline;
        overflow-x: auto;
        white-space: nowrap;
    }
    .varient-head-1 {
        position: relative;
        top: 25px;
    }
    .varient-head-2 {
        position: relative;
        top: 43px;
    }
}

@media only screen and (max-width: 400px) {
    .hide-in-mobile {
        display: none !important;
    }
    .varient-head-2 {
        position: relative;
        top: 43px;
    }
    .varient-mobile {
        display: flex;
        align-items: baseline;
        overflow-x: auto;
        white-space: nowrap;
    }
    .varient-head-1 {
        position: relative;
        top: 25px;
    }
    .varient-head-3 {
        position: relative;
        top: 55px;
    }
    .varient-inputs {
        width: 200px;
        padding: 2px 10px 15px 10px;
        gap: 10px;
    }
    .varient-inputs .form-control {
        min-height: 30px !important;
        font-size: 12px;
    }
    .accprofile {
        text-align: center;
    }
    .mb-2 {
        margin-bottom: 0rem !important;
    }
    .package-table-cell {
        position: sticky;
        left: 0;
        background-color: #fff !important;
        z-index: 1;
        font-size: 12px;
    }
    .mobile-margin-4 {
        margin-bottom: 10px;
    }
    .vendor-checkout-section h4 {
        font-size: 16px;
    }
    .vendor-checkout-section {
        justify-content: left;
    }
    .subscription-bg-color {
        padding: 20px 8px;
    }
    .subscription-content-bg {
        padding: 15px;
    }
    .mobile-margin-1 {
        margin-top: 20px;
    }
    .mobile-margin-3 {
        margin-top: 10px;
    }
    .mobile-margin-2 {
        margin-bottom: 20px;
    }
    .vendor-enquiry-page {
        display: block;
    }
    .add-product-checkboxes {
        grid-template-columns: 100%;
    }
    .mobile-screen-button {
        font-size: 12px;
        padding: 5px;
        min-height: 30px;
    }
    .header-section {
        position: relative;
    }
    .vendor-product {
        display: block;
    }
    .slider-heading {
        display: block;
    }
    .vendor-heading h5 {
        font-size: 18px;
    }
    .vendor-slider-section {
        margin-left: 0%;
    }
    .pd-zero {
        padding: 0px !important;
    }
    .mt-8 {
        margin-top: 20px;
    }
    .slider-banner-vendor {
        display: block;
    }
    .mt-5 {
        margin-top: 1rem;
    }
    .dashboard-btn {
        float: initial;
        margin-left: 5px;
        font-size: 14px;
        min-height: 30px;
        margin-top: 10px;
    }
    .tax-switch {
        margin-top: 0px;
        font-size: 18px;
        float: initial;
    }
    .breadcrumb-block li {
        padding: 0 5px 0 0;
    }
    .seller-dashboard-btn {
        font-size: 14px;
        padding: 5px 7px;
        min-height: 20px !important;
    }
    .package-card-block {
        padding: 10px;
    }
    .top-header-mobile-bg {
        background-color: #000;
        border-radius: 4px;
    }
    .caetfory-small-block {
        width: 150px;
        height: 154px;
    }
    .category-main-blk {
        display: flex;
        overflow: auto;
        grid-template-columns: 31% 31% 32%;
        gap: 10px;
    }
    .caetfory-small-block h5 {
        overflow: hidden;
        height: 40px;
        color: #000;
    }
    .caetfory-small-block {
        width: 100%;
        height: 100%;
    }
    .star-rating button {
        padding: 0px;
    }
    .hide-in-desktop {
        display: block !important;
    }
    .margin-top-2 {
        margin-top: 15px;
    }
    .search-bar-width {
        width: 100%;
        margin-top: 20px;
    }
    .header-items {
        align-items: center;
    }
    .topmenublk ul li a {
        padding: 10px 0px;
        font-size: 12px;
    }
    .footer-logo {
        padding: 0px 0 0 0px;
        width: 40%;
    }
    .footer-para {
        padding: 15px 0px 0px 0px;
    }
    .footer-pd-1 {
        padding: 0px 0px 0px 10px;
    }
    .footer-mr-1 {
        margin-top: 15px;
    }
    .sell-aladin-btn {
        margin-right: 0px;
        font-size: 12px;
    }
    .sell-aladin-btn span {
        padding: 2px 8px
    }
    .logo-block img {
        height: 60px;
        position: relative;
        top: 4px;
    }
    .searchheade-block .btn-search {
        padding: 3px 12px !important;
        margin-top: 5px;
    }
    .searchheade-block {
        margin-bottom: 10px;
        padding: 2px 5px;
    }
    .topmenublk ul li img {
        margin-left: 3px;
        width: 15px;
    }
    .sortby-block {
        justify-content: space-between;
    }
    .select-location button {
        font-size: 12px;
    }
    .pop-city {
        margin-left: 3px;
        margin-right: 3px;
    }
    .gridlist-item-blk {
        justify-content: space-between;
        margin-bottom: 12px;
    }
    .sortby-block .form-select {
        width: 142px;
    }
    .shopsearch-grid-blk {
        padding: 10px 5px;
        font-size: 12px;
    }
    .gridlist-btn {
        width: 28px;
        height: 30px;
        margin-right: 5px;
        font-size: 16px;
    }
    .mobile-filters {
        display: flex;
        flex-direction: column-reverse;
    }
    .sub-category-product img {
        width: 100%;
    }
    .mobile-wish-cart {
        display: flex;
        padding-left: 0px;
        list-style-type: none;
        gap: 10px;
        align-items: center;
        margin-bottom: 0rem;
    }
    .mobile-user-dropdown {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 10px;
    }
    .chat-with-us button {
        padding: 15px 0px;
        right: 0px;
        color: #fff;
        margin-right: 0px;
    }
    .language-dropdown {
        width: 100%;
        background: #000;
        color: #fff;
        cursor: pointer;
    }
    .top-header-dropdown {
        display: flex;
        justify-content: space-between;
    }
    .avon-search-input {
        width: 87%;
        margin-left: 0px;
    }
    .logo-block {
        width: 40%;
        margin: auto;
    }
    /* .instruc-cont-blk h4 {
        overflow: hidden;
        height: 40px;
    } */
    .brand-block img {
        height: 100px;
        padding: 10px;
    }
    .brand-block {
        height: 140px;
    }
    .sub-category-info img {
        margin-top: 20px;
    }
    .free-shipping-scroll {
        display: contents;
        overflow-x: scroll;
    }
    .product-block p {
        letter-spacing: -1px;
    }
    .foot-menu-list {
        margin-left: -10px;
    }
    .footer-contact-list {
        display: flex;
        align-items: flex-start;
        gap: 15px;
    }
    .footer-contact-list p {
        color: #fff;
        margin-bottom: 0rem;
    }
    .blog-grid-block img {
        width: 100px;
        height: 100px !important;
    }
    .mobile-blogs {
        display: flex;
        gap: 10px;
    }
    .blog-grid-block {
        margin: 0 0 15px;
    }
    .new-league-block {
        min-height: 150px;
    }
    .blog-grid-block h3 {
        font-size: 20px;
    }
    .btn-shop-white {
        padding: 4px 10px;
        min-height: 20px;
    }
    .league-cont-blk h3 {
        font-size: 26px;
        margin: 0 0 5px;
    }
    .league-cont-blk h5 {
        margin: 0 0 5px;
    }
    .category-main-blk {
        padding: 10px;
    }
    .mobile-shiiping-section {
        display: grid;
        grid-template-columns: 100% 100% 100% 100%;
    }
    .elec-cos-blk {
        background-size: 100% 100% !important;
        margin-top: 10px;
    }
    .owl-prev {
        height: 24px;
    }
    .owl-next {
        height: 24px;
    }
    .owl-next span {
        position: relative !important;
        bottom: 13px !important;
        font-size: 32px !important;
    }
    .owl-prev span {
        position: relative !important;
        bottom: 13px !important;
        font-size: 32px !important;
    }
    .instruction-blk {
        display: flex;
        gap: 10px;
        text-align: left;
    }
    .instruc-cont-blk {
        display: block;
    }
    .instruc-img-blk {
        display: block;
    }
    .new-league-block {
        background-size: 100% 100% !important;
    }
    .CookieConsent {
        height: 250px !important;
    }
    .currency-dropdown {
        right: 0px;
    }
    .product-cont-blk {
        padding: 8px;
    }
    .timer-section span {
        margin-right: 0px;
    }
    .cart-drawer {
        overflow-y: auto;
    }
    .top-header-section {
        display: block !important;
    }
    .inquiry-modal-pedding {
        padding: 15px;
    }
    .product-detail-form-modal {
        padding-left: 10px;
    }
    .product-detail-form-modal select {
        max-width: 100%;
    }
    .product-detail-form-modal input {
        max-width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 980px) {
    .bottom-header-section {
        display: none;
    }
    .all-categories-link {
        font-size: 18px !important;
        padding: 15px;
    }
    .bottom-header-768 {
        display: block;
        padding: 15px;
    }
    .all-services-768 {
        background: #ffb800;
        margin-bottom: 20px;
    }
    .all-categories-768 li {
        margin-left: 25px;
        font-size: 18px;
    }
    .all-services-768 .navbar-toggler {
        background: #fff;
    }
    .mainmenu-block {
        float: right;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .clients-info-section {
        display: grid;
        grid-template-columns: 47% 47%;
    }
    .package-cards-btn button {
        padding: 5px 10px !important;
        font-size: 12px;
    }
    .whatsapp-icon-color {
        color: #fff;
    }
    .brand-and-sponser {
        display: block;
    }
    .top-header-mobile-bg {
        width: 100%;
        margin-left: 0px;
    }
    .top-header-section {
        display: block !important;
    }
    .dropbtn {
        color: #000 !important;
    }
    .timer-section span {
        margin-right: 0px;
    }
    .top-header-mobile-bg {
        width: 100%;
        margin-left: 0px;
    }
}

@media screen and (min-width: 376px) and (max-width: 599px) {
    .header-on-landscape {
        display: none;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .header-on-landscape {
        display: block;
    }
    .footer-mail-list span {
        padding: 0px;
    }
    .top-header-mobile-bg {
        padding: 10px;
        margin-bottom: 14px;
        width: 95%;
        margin-left: 20px;
        margin-top: 10px;
    }
    .topmenublk ul li a {
        color: #ffff !important;
    }
    .landscape-middle-section {
        display: flex;
        justify-content: center;
        gap: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
    .login-768 {
        width: 60% !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
    .hide-on-768 {
        display: none !important;
    }
    .section-768 {
        display: block;
    }
    .media-1024 {
        display: block !important;
    }
    .bootem-on-768 {
        display: none;
    }
    .dropdown-1 {
        width: 100px !important;
    }
    .dropdown-2 {
        width: 120px !important;
        margin-right: 20px;
    }
    .chat-with-us button {
        margin-left: 15px;
    }
    /* .bottom-header-section {
        display: none;
    } */
    .mainmenu-block {
        float: right;
        margin-top: 8px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
    .shopsearch-grid-blk {
        display: none;
    }
    .select-location button img {
        margin-left: 10px;
    }
    .search {
        margin: 0px;
    }
    .section-media-900 {
        display: block;
    }
}

@media screen and (min-width: 801px) and (max-width: 1300px) {
    .media-1024 {
        display: block;
    }
    .star-rating button {
        padding: 1px;
    }
    .our-clients-cols {
        grid-template-columns: 16% 16% 16% 16% 16% 16% 16%;
        gap: 10px;
        overflow-x: auto;
        white-space: nowrap;
    }
    .clients-info-section {
        margin: 20px 0px 0px 10px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    .bottom-header-section {
        position: relative;
    }
    /* .mobile-hidden {
        display: block;
    } */
    .hide-on-1024 {
        display: none;
    }
    .top-header-dropdown {
        gap: 30px;
    }
    .search-bar-width {
        width: 600px;
        margin: auto;
        margin-top: 7px;
    }
    .sell-aladin-btn {
        font-size: 12px;
        margin-top: 7px;
    }
    .mainmenu-block .navbar .nav-link {
        font-size: 15px;
    }
}

@media screen and (min-width: 980px) and (max-width: 1230px) {
    .all-department-menu {
        width: 165px;
    }
    .all-categories-link {
        padding: 10px 6px;
        font-size: 16px;
    }
}

@media screen and (min-width: 401px) and (max-width: 900px) {
    .hide-in-mobile {
        display: none !important;
    }
    .mobile-hidden {
        display: block;
    }
    .bottom-header-section {
        position: relative;
    }
    .timer-section span {
        margin-right: 0px;
    }
    /* .header-on-landscape {
        display: block;
    } */
    .fea-pro-cont {
        width: 100%;
        display: block;
    }
    .feat-pro-img {
        width: 100%;
        display: block;
    }
    .dropbtn {
        color: #fff;
    }
    .header-on-landscape1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .top-header-section {
        display: none;
    }
    .clients-info-section {
        margin: 20px 0px 0px 10px;
        display: grid;
        grid-template-columns: 47% 47%;
    }
    .sub-category-info img {
        margin-top: 20px;
    }
    .free-shipping-scroll {
        display: contents;
        overflow-x: scroll;
    }
    .sub-category-product img {
        width: 100%;
    }
    .sub-category-product a {
        color: #000;
    }
    .hide-in-desktop {
        display: block !important;
        /* width: 70%; */
        margin: auto;
    }
    .search-bar-width .hide-in-desktop input {
        border-radius: 40px !important;
    }
    .margin-top-2 {
        margin-top: 15px;
    }
    .search-bar-width {
        width: 100%;
        margin-top: 20px;
    }
    .header-items {
        align-items: center;
    }
    .topmenublk ul li a {
        padding: 10px 0px;
        font-size: 15px;
    }
    .footer-logo {
        padding: 0px 0 0 0px;
        width: 40%;
    }
    .footer-para {
        padding: 15px 0px 0px 0px;
    }
    .footer-pd-1 {
        padding: 0px 0px 0px 10px;
    }
    .footer-mr-1 {
        margin-top: 15px;
    }
    .sell-aladin-btn {
        margin-right: 0px;
        font-size: 12px;
    }
    .sell-aladin-btn span {
        padding: 2px 8px
    }
    .logo-block img {
        height: 60px;
        position: relative;
        top: 4px;
    }
    .searchheade-block .btn-search {
        padding: 3px 12px !important;
        margin-top: 5px;
    }
    .searchheade-block {
        margin-bottom: 10px;
        padding: 2px 5px;
    }
    .topmenublk ul li img {
        margin-left: 3px;
        width: 20px;
    }
    .sortby-block {
        justify-content: space-between;
    }
    .select-location button {
        font-size: 12px;
    }
    .pop-city {
        margin-left: 3px;
        margin-right: 3px;
    }
    .gridlist-item-blk {
        justify-content: space-between;
        margin-bottom: 12px;
    }
    .sortby-block .form-select {
        width: 142px;
    }
    .shopsearch-grid-blk {
        padding: 10px 5px;
        font-size: 12px;
    }
    .gridlist-btn {
        width: 28px;
        height: 30px;
        margin-right: 5px;
        font-size: 16px;
    }
    .mobile-filters {
        display: flex;
        flex-direction: column-reverse;
    }
    .gridlist-item-blk select {
        width: 120px;
        padding: 5px;
        font-size: 12px;
    }
    .pop-cities {
        border: none;
        margin-left: 0px;
    }
    .clients-info-section {
        /* display: block; */
        /* grid-template-columns: 50% 50% 50% 50% 50%; */
        /* overflow-x: auto;
        white-space: nowrap; */
    }
    .our-clients-cols {
        grid-template-columns: 25% 25% 25% 25% 25% 25% 25%;
        gap: 10px;
        overflow-x: auto;
        white-space: nowrap;
    }
    .our-clients-cols::-webkit-scrollbar {
        display: none;
    }
    .blog-grid-block p {
        line-height: 1.2;
        max-height: 2.4em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .product-img-blk img {
        /* min-width: 100% !important; */
        height: 120px;
    }
    .product-block {
        margin-bottom: 20px;
        padding: 0px;
    }
    .mobile-wish-cart {
        display: flex;
        padding-left: 0px;
        list-style-type: none;
        gap: 3px;
        align-items: center;
        margin-bottom: 0rem;
    }
    .mobile-user-dropdown {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 10px;
    }
    .chat-with-us button {
        padding: 15px 0px;
        right: 0px;
        color: #fff;
        margin-right: 0px;
    }
    .language-dropdown {
        width: 100%;
        background: #000;
        color: #fff;
        cursor: pointer;
    }
    .top-header-dropdown {
        display: flex;
        justify-content: space-between;
        gap: 5px;
    }
    .top-header-mobile-bg {
        background-color: #000;
        border-radius: 4px;
    }
    .avon-search-input {
        width: 87%;
        margin-left: 0px;
    }
    .logo-block {
        width: 40%;
        margin: auto;
    }
    .category-main-blk {
        display: flex;
        overflow: auto;
        grid-template-columns: 31% 31% 32%;
        gap: 10px;
    }
    .caetfory-small-block h5 {
        overflow: hidden;
        height: 40px;
        color: #000;
    }
    .caetfory-small-block {
        width: 150px;
        height: 154px;
    }
    .category-main-blk {
        padding: 10px;
    }
    /* .instruc-cont-blk h4 {
        overflow: hidden;
        height: 40px;
    } */
    .product-block p {
        letter-spacing: -1px;
    }
    .foot-menu-list {
        margin-left: -10px;
    }
    .footer-contact-list {
        display: flex;
        align-items: flex-start;
        gap: 15px;
    }
    .footer-contact-list p {
        color: #fff;
        margin-bottom: 0rem;
    }
    .blog-grid-block img {
        width: 100px;
        height: 100px !important;
    }
    .mobile-blogs {
        display: flex;
        gap: 10px;
    }
    .blog-grid-block {
        margin: 0 0 15px;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    .blog-grid-block>div {
        width: 60%;
    }
    .new-league-block {
        min-height: 150px;
    }
    .new-league-block {
        min-height: 150px;
    }
    .blog-grid-block h3 {
        font-size: 20px;
    }
    .btn-shop-white {
        padding: 4px 10px;
        min-height: 20px;
    }
    .league-cont-blk h3 {
        font-size: 26px;
        margin: 0 0 5px;
    }
    .league-cont-blk h5 {
        margin: 0 0 5px;
    }
    .mobile-shiiping-section {
        display: grid;
        grid-template-columns: 100% 100% 100% 100%;
    }
    .elec-cos-blk {
        background-size: 100% 100% !important;
        margin-top: 10px;
    }
    .owl-prev {
        height: 24px;
    }
    .owl-next {
        height: 24px;
    }
    .owl-next span {
        position: relative !important;
        bottom: 13px !important;
        font-size: 32px !important;
    }
    .owl-prev span {
        position: relative !important;
        bottom: 13px !important;
        font-size: 32px !important;
    }
    .instruction-blk {
        display: flex;
        gap: 10px;
        text-align: left;
    }
    .instruc-cont-blk {
        display: block;
    }
    .instruc-img-blk {
        display: block;
    }
    .new-league-block {
        background-size: 100% 100% !important;
    }
    .CookieConsent {
        height: 250px !important;
    }
    .currency-dropdown {
        right: 0px;
    }
    .product-cont-blk {
        padding: 8px;
    }
    .cart-drawer {
        overflow-y: auto;
    }
    .package-card-block {
        padding: 10px;
    }
    .package-card-block h3 del {
        font-size: 14px;
        margin-left: 7px;
    }
    .package-card-block h3 {
        font-size: 18px;
    }
    .package-table thead {
        zoom: 0.8;
    }
    .package-cards-btn button {
        padding: 5px 10px !important;
        font-size: 12px;
    }
    .inquiry-modal-pedding {
        padding: 15px;
    }
    .product-detail-form-modal {
        padding-left: 10px;
    }
    .product-detail-form-modal select {
        max-width: 100%;
    }
    .product-detail-form-modal input {
        max-width: 100%;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        white-space: nowrap;
    }
    .dashboard-dropdown-toggle {
        display: block;
    }
}

@media(max-width: 600px) {
    .clients-info-section {
        grid-template-columns: 96%;
    }
    .hide-in-desktop {
        /* width: 78%; */
    }
}

@media(max-width: 416px) {
    .hide-in-desktop {
        width: 93%;
    }
    .our-clients-cols {
        grid-template-columns: 25% 25% 25% 25% 25% 25% 25%;
        gap: 10px;
        overflow-x: auto;
        white-space: nowrap;
    }
    .blog-grid-block>div {
        width: 60%;
    }
    .feature-pro-blkm {
        text-align: center;
    }
    .feature-pro-blkm>a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .fea-pro-cont {
        width: 100%;
    }
    .currency-section {
        justify-content: center;
    }
    .feature-pro-blk {
        text-align: center;
    }
}

.small-input {
    width: 50px;
    margin-right: 20px;
}

.digital-services-parent  .dropdown-section{
    flex-wrap: wrap;
 }

@media (max-width: 1199px) {
    .service-detail-page-detail .scards .card .price-cont p {
        font-size: 16px;
    }
    .service-detail-page-detail .scards .card .price-cont .heart i {
        font-size: 14px !important;
    }
    .servicecat-comp .cards .cat-logo {
        width: 120px;
        height: 123px;
    }
    .servicecat-comp .cards .card h5 {
        font-size: 19px;
    }
    .product-seller-container .chat-btns {
        flex-direction: column;
        gap: 0.7rem;
    }
    .product-seller-container .chat-btns a button{
        width: 100%;
    }
}

@media(max-width: 1080px) {
    .sidebar-dashboard .user-details-side {
        flex-direction: column;
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .sidebar-dashboard .user-info {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 992px) {
    .service-detail-page>div {
        flex-direction: column-reverse;
        gap: 1rem;
    }
    .service-detail-page .right,
    .service-detail-page-detail {
        width: 100%;
    }
    .package-box {
        max-width: 70%;
        margin: auto;
    }
    .package-box-list-button {
        width: 60%;
        align-items: center;
    }
    .servicecat-comp .cards .cat-logo {
        width: 110px;
        height: 100px;
    }
    .servicecat-comp .cards .cat-logo>img {
        width: 50px;
        height: 40px;
    }
    .servicecat-comp .cards .card h5 {
        font-size: 16px;
        font-weight: 500;
    }
}

@media(max-width: 860px) {
    .servicecat-comp .cards .cat-logo {
        width: 105px;
        height: 90px;
    }
    .servicecat-comp .cards .card h5 {
        font-size: 14px;
    }
    .servicecat-comp .cards .card {
        width: 14.5%;
    }
}

@media(max-width: 767px) {
    .compare-package .cp-container {
        max-width: 100%;
        overflow-x: auto;
    }
    .compare-package .cp-container table {
        min-width: 800px;
    }
    .package-box {
        max-width: 95%;
    }
    .it-services .main-cont .top-heading h6,
    .it-services .main-cont .top-heading a {
        font-size: 20px;
    }
    .servicecat-comp .cards .card h5 {
        margin-bottom: 0.4rem;
    }
    .servicecat-comp .cards .card {
        width: 17%;
    }
    .electronic-cosmatic-section .row .col-md-6:last-child {
        display:none;
    }
    .product-details-main-block .product-imgslider,
    .product-details-main-block .card-body-blk .col-md-4 {
        text-align: center;
    }
    .product-details-main-block .card-body-blk .col-md-4 img{
        max-height: 230px;
        margin: auto;
    }
    .product-details-main-block .card-body-blk .col-md-8{
        display: none;
    }
    .product-seller-container .chat-btns {
        flex-direction: row;
    }
   
    #filterSidebar  nav {
        top: 105px !important;
    }
    .services-banner .cards {
        margin: auto;
    padding-left: 0;
    padding-right: 0;
    }

 
    
}

@media(max-width: 575px) {
    .servicecat-comp .cards .cat-logo {
        width: 90px;
        height: 75px;
    }
    .servicecat-comp .cards .card h5 {
        font-size: 13px;
    }
    .servicecat-comp .cards .card {
        width: 19%;
    }
    .it-services .main-cont .top-heading a {
        font-size: 16px;
        text-wrap: nowrap;
        margin-left: 0.6rem;
    }
    .service-detail-page-detail .faq-container dt {
        font-size: 16px;
    }
    .service-detail-page-detail .faq-container dt img {
        width: 26px;
        height: 26px;
        right: 1rem;
        top: 0.3rem;
    }
    .faq-container>div {
        padding: 0.6rem 1rem;
        margin: 0.5rem auto;
    }
}

@media(max-width:460px) {
    .servicecat-comp .cards .cat-logo {
        width: 70px;
        height: 58px;
    }
    .servicecat-comp .cards .cat-logo>img {
        width: 36px;
        height: 30px;
    }
    .servicecat-comp .cards .card .card-body {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
    .service-detail-page-detail .faq-container dt {
        font-size: 14px;
        padding-right: 2rem;
    }
    .service-detail-page-detail .faq-container dt img {
        width: 22px;
        height: 22px;
        right: 0.5rem;
        top: 0.6rem;
    }
    .package-box p,
    .package-box-list {
        font-size: 14px;
    }
    .service-detail-page .right {
        padding-right: 0 !important;
    }
    .service-detail-page-detail {
        padding-left: 15px;
        padding-right: 15px;
    }
    #filterSidebar nav {
        top: 68px !important;
    }
}

@media(max-width:416px) {
    .servicecat-comp .cards .card {
        width: 24%;
    }
}



/* .digi-service-card{
    height: 210px;
} */